import axios, { AxiosResponse } from 'axios';
import JWTStorage from '../storage/localStorage/jwtStorage';
// constants
import appRoutes from '../constants/routes';
// services
import NotificationService from '../services/NotificationService';

export const API_URL = process.env.REACT_APP_API_ROOT || 'http://localhost:8000';

let refreshToken: string | null = '';
const checkInterval = 60 * 1000;

export const setAxiosAuthorizationHeaders = (token: string | null) => {
  refreshToken = token;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

// update axios token if we have it stored in local storage.
if (JWTStorage.exists()) {
  const token = JWTStorage.getJWT();
  setAxiosAuthorizationHeaders(token);
}

let checkRefreshToken = true;

function handleRefreshToken(response: AxiosResponse<any>) {
  checkRefreshToken = false;

  const newRefreshToken = response.headers['jwt-refreshed'];

  if (newRefreshToken && newRefreshToken !== refreshToken) {
    setAxiosAuthorizationHeaders(newRefreshToken);
    JWTStorage.setJWT(newRefreshToken);
  }

  setTimeout(() => {
    checkRefreshToken = true;
  }, checkInterval);
}

axios.interceptors.response.use(
  function onFulfilled(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (checkRefreshToken) {
      handleRefreshToken(response);
    }

    return response;
  },
  function onRejected(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error && error.response?.status === 401) {
      const authenticationURLRegex = /^.+\/auth\/token\/?$/;

      if (!authenticationURLRegex.test(error.config.url)) {
        // clear JWT and redirect to login when user is not authorized.
        JWTStorage.removeJWT();
        // Disable notifications until redirect. The user is redirected and the forced lock state will be lost anyway.
        NotificationService.forceLock = true;
        // eslint-disable-next-line
        window.location.replace(appRoutes.login);
      }
    }

    return Promise.reject(error);
  }
);
