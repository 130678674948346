/**
 * Compute actual page size.
 * @param pageSize page size.
 * @param count count results.
 */

// default value for pageNumber is set for cases where we use getPageSize function
// for just 1 page like in Equity Touch Integration page, to not break logic for
// multi-pages grids
export const getPageSize = (pageSize: number, count: number, pageNumber: number = 0) => {
  const lastPage = Math.ceil(count / pageSize);

  if (pageNumber === lastPage && pageNumber !== 1) {
    return count % pageSize;
  }

  return pageSize >= count ? count : pageSize;
};
