import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { Dictionary } from 'lodash';
import queryString from 'query-string';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
// models
import { CompanyProfile } from '@optx/models/Company';
import { PreselectedFilter } from '@optx/models/filters';
import { SearchPayload } from '@optx/models/api/contacts';
import { APIBoolean } from '@optx/models/api/generic';
import {
  OpinionAiDescription,
  RegenerateAiDescription,
  AiDescriptionResponse,
  ViewSimilarCompaniesOnGrid,
  AiRelationshipSummary,
  AiRelationshipSummaryResponse,
} from './interfaces';
import { SuccessErrorCallback } from '@optx/models/callback';
import { ChangedCompanyFieldsPayload } from '@optx/features/company/audit-trail/state/interfaces';
// constants
import { CompanyProfileTabs } from '@optx/constants/routes';
// services
import { CompanyService, UserService, FilterService } from '@services/api';
import NotificationService from '@services/NotificationService';
// redux
import { selectors as longCardSelectors } from '@features/long-card/company-card/state';
import { actions as userActions, selectors as userSelectors } from '@redux/user/information';
import { actions as filterActions } from '@redux/company/filters';
import { selectors as gridFilterSelectors } from '@features/grid/filter';
import { actions as searchActions } from '@features/grid/search';
import { UserInformation } from '@optx/models/user';
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import * as actions from './actions';
import * as selectors from './selectors';
import { getErrorMessage } from '@optx/utils/api/errors';

export function* fetchCompanyProfileSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    const res: AxiosResponse<false | CompanyProfile> = yield call(
      CompanyService.getProfile,
      companyId
    );

    if (res.data && res.data.source_tag) {
      yield put(actions.fetchCompanyProfileSuccess(res.data));
    } else {
      const errorMessage = 'Failed to fetch company Profile!';
      yield put(actions.fetchCompanyProfileFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Failed to fetch company Profile!';
    yield put(actions.fetchCompanyProfileFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* backToSearchResultsSaga(action: PayloadAction<string>) {
  const referrer = action.payload;
  const isAnalyst: boolean = yield select(userSelectors.getIsAnalyst);

  try {
    yield put(push(referrer));

    const user: AxiosResponse<UserInformation> = yield call(UserService.getUserInfo);
    // @ts-ignore
    const filters = yield call(FilterService.getOptions);

    if (user.data && filters.data) {
      yield put(userActions.fetchUserInformationSuccess(user.data));
      yield put(
        filterActions.fetchCompanyFiltersSuccess(filters.data.filters, {
          defaultViews: filters.data.default_views,
          searchOrigin: filters.data.search_origin,
          settings: user.data.settings,
          isAnalyst,
        })
      );
    } else {
      const errorMessage = 'Failed to fetch User Information!!!';
      yield put(userActions.fetchUserInformationFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (error: any) {
    const errorMessage = 'Failed to fetch User Information!!!';
    yield put(userActions.fetchUserInformationFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

export function* regenerateAiDescriptionSaga(action: PayloadAction<(value: string) => void>) {
  const callback = action.payload;
  const { tab } = queryString.parse(window.location.search);

  try {
    let profile: CompanyProfile;

    const profileState: CompanyProfile = yield select(selectors.getProfile);
    const longCardState: CompanyProfile = yield select(longCardSelectors.getProfile);

    if (profileState?.company_id) {
      profile = profileState;
    } else {
      profile = longCardState;
    }

    const searchPayload: RegenerateAiDescription = {
      companyId: profile.company_id,
      companyUrl: profile.company_url,
      companyName: profile.company_name,
    };

    const res: AxiosResponse<AiDescriptionResponse> = yield call(
      CompanyService.fetchRegenerateAiDescription,
      searchPayload
    );

    if (res.data && res.data.status) {
      yield put(
        actions.regenerateAiDescriptionSuccess({ ...res.data, companyId: profile.company_id })
      );

      callback(res.data.description);

      const { pageSize } = yield select(auditSelectors.getPagination);

      if (tab === CompanyProfileTabs.HISTORY) {
        const field: string = yield select(auditSelectors.getSearchQuery);
        const optx: APIBoolean = yield select(auditSelectors.getOPTX);
        const internal: APIBoolean = yield select(auditSelectors.getInternal);
        const startDate: string = yield select(auditSelectors.getStartDate);
        const endDate: string = yield select(auditSelectors.getEndDate);

        const fetchPayload: ChangedCompanyFieldsPayload = {
          companyId: profile.company_id,
          pagination: { pageNumber: 1, pageSize },
          field,
          internal,
          optx,
          startDate,
          endDate,
        };

        yield put(auditActions.fetchChangedCompanyFields(fetchPayload));
      }
    } else {
      const errorMessage = res.data.message;
      yield put(actions.regenerateAiDescriptionFail());
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to regenerate AI Description!');
    yield put(actions.regenerateAiDescriptionFail());
    NotificationService.error(errorMessage);
  }
}

export function* opinionAiDescriptionSaga(
  action: PayloadAction<boolean, any, SuccessErrorCallback>
) {
  const { tab } = queryString.parse(window.location.search);

  try {
    const opinion: boolean = action.payload;
    const callback = action.meta;

    let profile: CompanyProfile;

    const profileState: CompanyProfile = yield select(selectors.getProfile);
    const longCardState: CompanyProfile = yield select(longCardSelectors.getProfile);

    if (profileState?.company_id) {
      profile = profileState;
    } else {
      profile = longCardState;
    }

    const searchPayload: OpinionAiDescription = {
      companyId: profile.company_id,
      companyUrl: profile.company_url,
      companyName: profile.company_name,
      opinion,
    };

    const res: AxiosResponse<AiDescriptionResponse> = yield call(
      CompanyService.fetchOpinionAiDescription,
      searchPayload
    );

    if (res.data && res.data.status) {
      yield put(
        actions.opinionAiDescriptionSuccess({ ...res.data, companyId: profile.company_id })
      );
      callback(res.data.description);

      const { pageSize } = yield select(auditSelectors.getPagination);

      if (tab === CompanyProfileTabs.HISTORY) {
        const field: string = yield select(auditSelectors.getSearchQuery);
        const optx: APIBoolean = yield select(auditSelectors.getOPTX);
        const internal: APIBoolean = yield select(auditSelectors.getInternal);
        const startDate: string = yield select(auditSelectors.getStartDate);
        const endDate: string = yield select(auditSelectors.getEndDate);

        const fetchPayload: ChangedCompanyFieldsPayload = {
          companyId: profile.company_id,
          pagination: { pageNumber: 1, pageSize },
          field,
          internal,
          optx,
          startDate,
          endDate,
        };

        yield put(auditActions.fetchChangedCompanyFields(fetchPayload));
      }
    } else {
      const errorMessage = res.data.message;
      yield put(actions.opinionAiDescriptionFail());
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to set Opinion for AI Description!');
    yield put(actions.opinionAiDescriptionFail());
    NotificationService.error(errorMessage);
  }
}

export function* viewSimilarCompaniesOnGridSaga(action: PayloadAction<ViewSimilarCompaniesOnGrid>) {
  const { pathname } = action.payload;
  const profileInfo: CompanyProfile = yield select(selectors.getProfile);
  const customClearedFilter: Dictionary<PreselectedFilter> = yield select(
    gridFilterSelectors.getCustomClearedFilter('advancedSearch')
  );

  const listOfCompetitorsIds = profileInfo.competitors.map(competitor => competitor.company_id);

  listOfCompetitorsIds.push(profileInfo.company_id);

  const customFilter = {
    ...customClearedFilter,
    similarCompanies: {
      label: profileInfo.company_name,
      value: listOfCompetitorsIds.toString(),
    },
  };

  const payloadData: Partial<SearchPayload> = {
    filter: customFilter,
    isSimilarCompaniesSearch: true,
  };

  yield put(searchActions.searchCompanies({ gridKey: 'advancedSearch', data: payloadData }));
  yield put(push(pathname));
}

export function* aiRelationshipSummarySaga(
  action: PayloadAction<boolean, any, SuccessErrorCallback>
) {
  try {
    const opinion: boolean = action.payload;
    const callback = action.meta;

    const profile: CompanyProfile = yield select(selectors.getProfile);

    const searchPayload: AiRelationshipSummary = {
      companyId: profile.company_id,
      companyName: profile.company_name,
      companyOwner: profile.company_owner,
      companyUrl: profile.company_url,
      opinion,
    };

    const res: AxiosResponse<AiRelationshipSummaryResponse> = yield call(
      CompanyService.fetchAiRelationshipSummary,
      searchPayload
    );

    if (res.data && res.data.status) {
      yield put(actions.aiRelationshipSummarySuccess(res.data));

      if (callback) {
        callback();
      }
    } else {
      const errorMessage = res.data.message;
      yield put(actions.aiRelationshipSummaryFail());
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Failed to set Relationship Summary Highlights!');
    yield put(actions.aiRelationshipSummaryFail());
    NotificationService.error(errorMessage);
  }
}

export default function* companyProfileSaga() {
  yield takeLatest(actions.fetchCompanyProfile, fetchCompanyProfileSaga);
  yield takeLatest(actions.backToSearchResults, backToSearchResultsSaga);
  yield takeLatest(actions.regenerateAiDescription, regenerateAiDescriptionSaga);
  yield takeLatest(actions.opinionAiDescription, opinionAiDescriptionSaga);
  yield takeLatest(actions.viewSimilarCompaniesOnGrid, viewSimilarCompaniesOnGridSaga);
  yield takeLatest(actions.aiRelationshipSummary, aiRelationshipSummarySaga);
}
