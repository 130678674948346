import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import Overflow from 'rc-overflow';
import { Typography, Button, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
// models
import { TouchFieldProps } from './interface';
import { SelectOption } from '@optx/models/Option';
// components
import MultiSelectAsyncField from './MultiSelectAsyncField';
// styles
import Styled from './MultiSelectField.styled';

const FILTER_TAG_DEFAULT_VALUE = [
  {
    value: 'None',
  },
];
interface AsyncSelectOption extends SelectOption {
  is_valid?: boolean;
  isValid?: boolean;
}

const MultiSelectAsyncFieldWrapper: React.FC<TouchFieldProps> = ({ field }) => {
  const [selectedList, , setSelectedList] = useField<AsyncSelectOption[]>(field.id);
  const [selected, setSelected] = useState<AsyncSelectOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AsyncSelectOption[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [filterTagValues, setFilterTagValues] = useState<AsyncSelectOption[]>(
    field.value ?? FILTER_TAG_DEFAULT_VALUE
  );
  const [isInitialAmount, setIsInitialAmount] = useState(true);

  useEffect(() => {
    setSelectedOptions(
      selected.map(item => ({ ...item, isValid: 'is_valid' in item ? item.is_valid : true }))
    );
    setFilterTagValues(selected ?? []);

    if (!isInitialAmount) {
      if (!isEqual(selected, selectedList.value)) {
        setSelectedList.setValue(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (field.value) {
      setSelected(field.value);
    }

    setIsInitialAmount(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  const handleCancel = () => {
    setIsVisible(false);
    setSelectedOptions(selected);
  };

  const handleApply = () => {
    setIsVisible(false);
    setSelected(
      selectedOptions.map(item => ({ ...item, isValid: 'is_valid' in item ? item.is_valid : true }))
    );
  };

  const handleDropdownKeyEvent = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'Enter') {
      handleApply();
    }
  };

  const changeHandler = (value: SelectOption[]) => {
    setSelectedOptions(value);
  };

  const menu = (
    <Styled.MenuWrapper onKeyDown={handleDropdownKeyEvent} tabIndex={0}>
      <div>
        <MultiSelectAsyncField
          label={field.label}
          endpoint={field.endpoint ?? ''}
          selectedOptions={selectedOptions}
          changeHandler={changeHandler}
        />
        <Row justify="end" className="menu-buttons-wrapper">
          <Button style={{ marginRight: 8 }} onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleApply}>
            Apply
          </Button>
        </Row>
      </div>
    </Styled.MenuWrapper>
  );

  return (
    <Styled.SelectWrapper span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <Styled.DropdownWrapper
        visible={isVisible}
        onVisibleChange={flag => {
          !flag ? handleCancel() : setIsVisible(flag);
        }}
        overlay={menu}
        trigger={['click']}
        placement="topRight"
      >
        <Button>
          <Styled.TriggerWrapper>
            <Overflow
              data={
                filterTagValues &&
                filterTagValues.map(item => ({
                  label: item.label,
                  value: item.value,
                  uniqueKey: item.value,
                }))
              }
              renderItem={(item: SelectOption) => (
                <div
                  style={{
                    margin: '0 8px 0 0',
                    padding: '4px 0px',
                    display: 'inline',
                  }}
                >
                  {item.label}
                  <span className="comma">,</span>
                </div>
              )}
              renderRest={items => (
                <div
                  style={{
                    margin: '0 2px 0 0px',
                    padding: '4px 8px',
                  }}
                >
                  + {items.length} more
                </div>
              )}
              maxCount="responsive"
              style={{ justifyContent: 'flex-start' }}
            />
          </Styled.TriggerWrapper>
          <DownOutlined />
        </Button>
      </Styled.DropdownWrapper>
    </Styled.SelectWrapper>
  );
};

export default React.memo(MultiSelectAsyncFieldWrapper);
