import React, { useEffect } from 'react';
import { Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
import { FieldUsedFor } from './interface';
// redux
import { selectors } from '@components/feature/company-individual-edit/state/index';

const { Option } = Select;

interface SingleSelectYearFoundedFieldProps {
  field?: BaseField;
  fieldUsedFor?: FieldUsedFor;
}

const SingleSelectYearFoundedField: React.FC<SingleSelectYearFoundedFieldProps> = ({
  field,
  fieldUsedFor,
}) => {
  const [selected, , setSelectedOption] = useField<number>(field?.id || '');

  const handleChange = (value: number) => {
    setSelectedOption.setValue(value);
  };

  const selectOptions = [
    { value: 'blank', label: 'None' },
    ...useSelector(selectors.companyIndividualEdit.yearFoundedOptions),
  ];

  useEffect(() => {
    if ((field?.value || field?.value === '') && fieldUsedFor !== 'editAllInfo') {
      if (!isEqual(field.value, selected.value)) {
        setSelectedOption.setValue(field.value);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            name={field.id}
            onChange={handleChange}
            getPopupContainer={trigger => trigger.parentElement!}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = option ? `${option?.children.props.children}` : '';

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {selectOptions &&
              selectOptions.map((option: OptionTypeBase) => (
                <Option key={option.value as string} value={Number(option.value as string)}>
                  <span>{option.label}</span>
                </Option>
              ))}
          </Select>
        </FormItem>
      </Col>
    );
  }

  return null;
};

export default React.memo(SingleSelectYearFoundedField);
