import { takeLatest, call, put, select } from 'redux-saga/effects';
import queryString from 'query-string';
import { PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
// models
import { AxiosResponse } from 'axios';
import { SelectOption } from '@optx/models/Option';
import {
  OpportunityPresentation,
  FinancialFieldType,
  UpdateOpportunity,
} from '@optx/models/Company';
import { PageInfo } from '@optx/models/table/Pagination';
import { APIBoolean } from '@optx/models/api/generic';
import { AddYearPayload, AddYearSuccessPayload } from './interfaces';
// services
import { CompanyService } from '../../../services/api';
import NotificationService from '../../../services/NotificationService';
// utils
import { getErrorMessage } from '@optx/utils/api/errors';
// redux
import {
  actions as auditActions,
  selectors as auditSelectors,
} from '@features/company/audit-trail/state';
import * as actions from './actions';
import { updateCompanyUserValuesSuccess } from '../user-values/actions';
import { actions as updateCompanyInfoActions } from '@redux/ui/modals/company-touches';
import { actions as loaderActions } from '@features/custom-global-loader';
import { actions as editAllInfoActions } from '@optx/features/edit-all-info/state';
import { actions as fundingRoundsActions } from '@redux/company/funding-rounds';

function* fetchOpportunityPresentationSaga(action: PayloadAction<number>) {
  const companyId = action.payload;

  try {
    // server retunes data or empty string if there is no data.
    const res: AxiosResponse = yield call(CompanyService.getOpportunityPresentation, companyId);
    let presentation = null;

    if (res.data) {
      presentation = { ...res.data };
      const sortedRevenues = orderBy(presentation.revenue, 'year', 'desc');
      const sortedEBITDA = orderBy(presentation.ebitda, 'year', 'desc');
      presentation.revenue = sortedRevenues;
      presentation.ebitda = sortedEBITDA;
    }

    yield put(actions.fetchOpportunityPresentationSuccess(presentation));
  } catch (e: any) {
    const errorMessage = 'Error company opportunity presentation!';
    yield put(actions.fetchOpportunityPresentationFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* updateOpportunityPresentationSaga(
  action: PayloadAction<UpdateOpportunity, any, boolean>
) {
  const presentation = { ...action.payload };
  const isFromHistory = action.meta;
  const funding = (presentation as unknown as OpportunityPresentation).funding;

  yield put(loaderActions.toggle({ loading: true }));

  try {
    const res: AxiosResponse<OpportunityPresentation> = yield call(
      CompanyService.addOpportunityPresentation,
      presentation
    );

    if (res.data) {
      yield put(actions.updateOpportunityPresentationSuccess(res.data));
      yield put(fundingRoundsActions.getCompanyFundingRounds(presentation.company_id));

      if ((presentation as unknown as OpportunityPresentation).funding?.capital_raised) {
        yield put(
          updateCompanyInfoActions.updateTouchCompanyInfoSuccess({
            company_id: presentation.company_id,
            funding: {
              capital_raised: res.data?.funding?.capital_raised?.toString(),
            },
          })
        );

        yield put(
          editAllInfoActions.updateCompanyEditAllInfoSuccess({
            company_id: presentation.company_id,
            funding: {
              capital_raised: res.data?.funding?.capital_raised?.toString(),
            },
          })
        );
      }

      if (
        funding?.last_round ||
        funding?.last_round_amount ||
        funding?.last_investors ||
        funding?.last_round_date
      ) {
        const lastRoundValue = res.data?.funding?.last_round.data.find(
          item => item.value === res.data.funding?.last_round.value
        );

        yield put(
          updateCompanyInfoActions.updateTouchCompanyInfoSuccess({
            company_id: presentation.company_id,
            funding: {
              capital_raised: res.data?.funding?.capital_raised?.toString(),
              last_round_amount: res.data?.funding?.last_round_amount,
              last_round: lastRoundValue as SelectOption<number>,
              acquiring_company:
                (presentation as unknown as OpportunityPresentation).funding?.acquiring_company ||
                '',
              last_investors: res.data?.funding?.last_investors || [],
              last_round_date: res.data?.funding?.last_round_date || undefined,
            },
          })
        );

        yield put(
          editAllInfoActions.updateCompanyEditAllInfoSuccess({
            company_id: presentation.company_id,
            funding: {
              capital_raised: res.data?.funding?.capital_raised?.toString(),
              last_round_amount: res.data?.funding?.last_round_amount,
              last_round: lastRoundValue as SelectOption<number>,
              acquiring_company:
                (presentation as unknown as OpportunityPresentation).funding?.acquiring_company ||
                '',
              last_investors: res.data?.funding?.last_investors || [],
              last_round_date: res.data?.funding?.last_round_date || undefined,
            },
          })
        );
      }

      if (res.data && res.data?.financial && res.data?.financial.revenue) {
        const sortedRevenues = orderBy(res.data?.financial.revenue, 'year', 'desc');
        let updatedSortedRevenues: FinancialFieldType[] | undefined;

        /*** Check if last revenue is from source so we can update revenue list***/
        if (res.data?.last_revenue?.from_source && res.data?.last_revenue?.value) {
          updatedSortedRevenues = [...sortedRevenues];
          const yearKey = sortedRevenues.findIndex(
            revenue => revenue.year === res.data?.last_revenue?.year
          );

          if (yearKey > -1) {
            updatedSortedRevenues[yearKey] = {
              value: res.data?.last_revenue?.value,
              year: sortedRevenues[yearKey].year,
              estimated: sortedRevenues[yearKey].estimated,
              from_source: res.data?.last_revenue?.from_source,
            };
          } else {
            updatedSortedRevenues.unshift({
              value: res.data?.last_revenue?.value,
              year: res.data?.last_revenue.year,
              estimated: res.data?.last_revenue.estimated,
              from_source: res.data?.last_revenue?.from_source,
            });
          }
        }

        yield put(
          updateCompanyUserValuesSuccess({
            companyId: presentation.company_id,
            fieldKey: 'revenue_list',
            financialList:
              (updatedSortedRevenues as FinancialFieldType[]) ||
              (sortedRevenues as FinancialFieldType[]),
            year: presentation.revenue?.year
              ? Number.parseInt(presentation.revenue?.year.toString())
              : undefined,
            value: presentation.revenue?.value ? presentation.revenue?.value : null,
          })
        );
      }

      if (res.data && res.data?.financial && res.data?.financial.ebitda) {
        const sortedEBITDA = orderBy(res.data?.financial.ebitda, 'year', 'desc');
        let updatedSortedEBITDA: FinancialFieldType[] | undefined;

        /*** Check if last ebitda is from source so we can update ebitda list***/
        if (res.data?.last_ebitda?.from_source && res.data?.last_ebitda?.value) {
          updatedSortedEBITDA = [...sortedEBITDA];
          const yearKey = sortedEBITDA.findIndex(
            revenue => revenue.year === res.data?.last_ebitda?.year
          );

          if (yearKey > -1) {
            updatedSortedEBITDA[yearKey] = {
              value: res.data?.last_ebitda?.value,
              year: sortedEBITDA[yearKey].year,
              estimated: sortedEBITDA[yearKey].estimated,
              from_source: res.data?.last_ebitda?.from_source,
            };
          } else {
            updatedSortedEBITDA.unshift({
              value: res.data?.last_ebitda?.value,
              year: res.data?.last_ebitda.year,
              estimated: res.data?.last_ebitda.estimated,
              from_source: res.data?.last_ebitda?.from_source,
            });
          }
        }

        yield put(
          updateCompanyUserValuesSuccess({
            companyId: presentation.company_id,
            fieldKey: 'ebitda_list',
            financialList:
              (updatedSortedEBITDA as FinancialFieldType[]) ||
              (sortedEBITDA as FinancialFieldType[]),
            year: presentation.ebitda?.year
              ? Number.parseInt(presentation.ebitda?.year.toString())
              : undefined,
            value: presentation.ebitda?.value ? presentation.ebitda?.value : null,
          })
        );
      }

      if (isFromHistory) {
        const pagination: PageInfo = yield select(auditSelectors.getPagination);
        const field: string = yield select(auditSelectors.getSearchQuery);
        const optx: APIBoolean = yield select(auditSelectors.getOPTX);
        const internal: APIBoolean = yield select(auditSelectors.getInternal);
        const startDate: string = yield select(auditSelectors.getStartDate);
        const endDate: string = yield select(auditSelectors.getEndDate);

        yield put(
          auditActions.fetchChangedCompanyFields({
            companyId: presentation.company_id,
            pagination,
            field,
            internal,
            optx,
            startDate,
            endDate,
          })
        );
      }

      NotificationService.success('Success updating company presentation!');
    } else {
      const errorMessage = 'Error updating company presentation!';
      yield put(actions.updateOpportunityPresentationFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = getErrorMessage(e, 'Error updating presentation!');
    yield put(actions.updateOpportunityPresentationFail(errorMessage));
    NotificationService.error(errorMessage);
  }

  yield put(loaderActions.toggle({ loading: false }));
}

function* addFinancialYearSaga(
  action: PayloadAction<{ companyId: number; value: AddYearPayload }>
) {
  const { companyId, value } = action.payload;
  const { annual_recurring_revenue, ebitda, gm, revenue } = value;

  const payload: AddYearSuccessPayload = {
    company_id: companyId,
    annual_recurring_revenue,
    ebitda,
    gm,
    revenue,
  };

  try {
    const res: AxiosResponse = yield call(CompanyService.addYearToOpportunityPresentation, payload);

    if (res.data) {
      yield put(actions.addFinancialYearSuccess(res.data));
      NotificationService.success('Success adding a new year!');
    } else {
      const errorMessage = 'Error to add a new year!';
      yield put(actions.addFinancialYearFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Error to add a new year!';
    yield put(actions.addFinancialYearFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

function* editFinancialYearSaga(
  action: PayloadAction<{ companyId: number; value: AddYearPayload }>
) {
  const { companyId, value } = action.payload;

  const customPayload = {
    company_id: companyId,
    financial: value,
  };

  try {
    const res: AxiosResponse = yield call(CompanyService.addOpportunityPresentation, customPayload);
    NotificationService.success('Year successfully edited!');
    yield put(actions.editFinancialYearSuccess(res.data));
  } catch (error) {
    const errorMessage = 'Failed to edit the year!';
    NotificationService.error(errorMessage);
  }
}

function* deleteFinancialYearSaga(action: PayloadAction<{ companyId: number; year: number }>) {
  const { companyId, year } = action.payload;

  const payload = {
    company_id: companyId,
    year,
  };

  const query = queryString.stringify(payload);

  try {
    const res: AxiosResponse = yield call(
      CompanyService.deleteYearToOpportunityPresentation,
      query
    );
    NotificationService.success('Success deleting a  year!');
    yield put(actions.deleteFinancialYearSuccess(res.data));
  } catch (error: any) {
    const errorMessage = 'Error to delete a  year!';
    NotificationService.error(errorMessage);
  }
}

export default function* opportunityPresentationSaga() {
  yield takeLatest(actions.fetchOpportunityPresentation.type, fetchOpportunityPresentationSaga);
  yield takeLatest(actions.updateOpportunityPresentation.type, updateOpportunityPresentationSaga);
  yield takeLatest(actions.deleteFinancialYear, deleteFinancialYearSaga);
  yield takeLatest(actions.addFinancialYear, addFinancialYearSaga);
  yield takeLatest(actions.editFinancialYear, editFinancialYearSaga);
}
