/* eslint-disable max-len */
import { CaseReducer, createReducer, current, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
// models
import {
  CompanyProfile,
  CompanyUserValue,
  Company,
  FinancialFieldType,
  OpportunityPresentation,
  ProductCategory,
  CompanyFundingRounds,
} from '@models/Company';
import { UpdateCompanyET } from '@models/equityTouch';
import { CompanyUserContact, ContactPhone } from '@models/api/contacts';
import {
  AddCompanyToWatchList,
  DeleteCompanyFromWatchList,
  CompanyWatchList,
} from '@models/WatchList';
import { TouchesFinancialPayload } from '@models/api/touches';
import { CompanyTouchSuccessResponse } from '@optx/models/company/CompanyTouch';
// constants
import {
  DEFAULT_COMPANY_OWNER_VALUE,
  DEFAULT_SENIOR_ADVISOR_VALUE,
  DEFAULT_OPPORTUNITY_TYPE,
} from '@constants/table/company';
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { ISO_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { updateRevenueList } from '@optx/utils/company';
import { showRationaleForStage } from '@optx/utils/helpers';
import { mapDescriptionOptions } from '@optx/utils/utils';
// redux
import { submitResearchRationaleSuccessReducer } from '@optx/redux/common/rationales/reducer';
import { fetchReducer } from '@redux/feature/fetch/reducers';
import { actions as searchActions } from '@features/grid/search';
import { actions as suggestedSearchActions } from '@redux/company/suggested-searches';
import { actions as favoritesActions } from '@redux/favorites';
import { actions as bulkSalesloftActions } from '@optx/features/bulk-actions/salesloft';
import { actions as equityTouchActions } from '@redux/company/equity-touch';
import { actions as researchRationaleActions } from '@features/add-research-rationale/state';
import { actions as companyOportunityTypeActions } from '@components/feature/company-individual-edit/state/oportunityType';
import { actions as DealTeamActions } from '@components/feature/company-individual-edit/state/dealTeam';
import { actions as activeActions } from '@redux/company/active';
import { actions as fundActions } from '@components/feature/company-individual-edit/state/fund';
import * as favoriteListsActions from '@optx/redux/favorite-lists/lists/actions';
import * as favoriteListsPopupActions from '@optx/redux/favorite-lists/lists-popup/actions';
import { actions as opportunityPresentationActions } from '@redux/company/opportunity-presentation';
import {
  createCompanyToListSuccess,
  deleteCompanyToListSuccess,
} from '@optx/redux/common/list/reducers';
import { actions as seniorDealTeamLeadActions } from '@components/feature/company-individual-edit/state/seniorDealTeamLead';
import { actions as dateFieldsActions } from '@components/feature/company-individual-edit/state/date-fields';
import { EditSeniorDealTeamLead } from '@components/feature/company-individual-edit/state/seniorDealTeamLead/interfaces';
import { EditDateFields } from '@components/feature/company-individual-edit/state/date-fields/interfaces';
import { EditDealTeam } from '@optx/components/feature/company-individual-edit/state/dealTeam/interfaces';
import { EditCompanyFund } from '@optx/components/feature/company-individual-edit/state/fund/interfaces';
import { EditOpportunityType } from '@optx/components/feature/company-individual-edit/state/oportunityType/interfaces';
import {
  CustomValue,
  EditFieldSuccess,
  UpdateFieldsPayload,
  UpdateVerifyCompanyPayload,
  MultipleFieldsValue,
} from '@optx/features/company/edit-fields/state/interfaces';
import { AddCompanyReviewSuccess } from '@optx/features/company-review/state/interfaces';
import {
  USER_VALUES_FIELDS,
  MONTH_DATE_YEAR_FORMAT_FIELDS,
} from '@optx/features/company/edit-fields/constants';
import { SelectOption } from '@optx/models/Option';
import { actions as inBusinessActions } from '@redux/company/in-business';
import { updateFinancialValues } from '@optx/utils/editFields';
import { actions as companyReviewActions } from '@optx/features/company-review/state/index';
import { DeleteTagPayload, SuccessCompanyTag } from '../tags/interfaces';
import { TrendingLinesUpdate, EmployeeGrowthPercentage } from '../user-values/interfaces';
import { actions as fundingRoundActions } from '../funding-rounds';
import {
  AiDescriptionResponse,
  AiRelationshipSummaryResponse,
  CompanyProfileState,
} from './interfaces';
import { actions as editFieldsActions } from '../../../features/company/edit-fields/state';
import { actions as portfolioActions } from '../portfolio';
import { actions as datePresentedActions } from '../date-presented';
import { actions as userValuesActions } from '../user-values';
import { actions as tagsActions } from '../tags';
import { actions as primaryContactsActions } from '../primary-contacts';
import * as actions from './actions';
import { actions as updateCompanyInfoActions } from '@redux/ui/modals/company-touches';
import { actions as editAllInfoActions } from '@optx/features/edit-all-info/state';
import { UPDATE_COMPANY_OPTX_SCORE } from '../user-values/types';
import { CompanyActivePayload } from '../active/interfaces';
import { sendOptxScoreRationaleSuccess } from '../score/actions';
import { OptxScoreRationale } from '../score/interfaces';

const initialState: CompanyProfileState = {
  data: null,
  loading: false,
  error: '',
  fetchedAt: null,
};

const fetchProfileReducer: CaseReducer<CompanyProfileState> = draftState => {
  draftState.data = null;
  draftState.loading = true;
  draftState.error = '';
};

const fetchProfileSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<CompanyProfile>
> = (draftState, action) => {
  const { payload } = action;
  draftState.loading = false;
  draftState.fetchedAt = new Date().toISOString();

  const defaultSelectedDescription = mapDescriptionOptions(payload.descriptions);

  const selectedDescription = defaultSelectedDescription[0]?.value;

  draftState.data = { ...payload, ...{ selectedDescription: selectedDescription } };

  updateRevenueList(draftState.data);
};

const setSelectedDescriptionReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<string | number>
> = (draftState, action) => {
  const { payload } = action;

  if (draftState.data) {
    draftState.data.selectedDescription = payload;
  }
};

const fetchProfileFailReducer: CaseReducer<CompanyProfileState, PayloadAction<string>> = (
  draftState,
  action
) => {
  draftState.loading = false;
  draftState.error = action.payload;
};

const addCompanyInBusinessEvaluationSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<
    {
      score: number;
      il_score: number;
      il_score_growth: number;
      scoreGrowth: number;
      companyId: number;
      isInBusiness: boolean;
    },
    any
  >
> = (draftState, action) => {
  const {
    companyId,
    isInBusiness,
    score,
    scoreGrowth,
    il_score: IlScore,
    il_score_growth: IlScoreGrowth,
  } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.is_in_business = isInBusiness;
    draftState.data.score = score;
    draftState.data.il_optx_score = IlScore;
    draftState.data.il_score_growth = IlScoreGrowth;
    draftState.data.optx_score_growth = scoreGrowth;
    draftState.data.score_growth = scoreGrowth?.toString() || null;
  }
};

const updateCompanyOptxScoreSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<
    {
      score: number;
      companyId: number;
      scoreGrowth: number;
      il_score: number;
      il_score_growth: number;
    },
    any
  >
> = (draftState, action) => {
  const { score, scoreGrowth, il_score: IlScore, il_score_growth: IlScoreGrowth } = action.payload;

  if (draftState.data) {
    draftState.data.score = score;
    draftState.data.il_optx_score = IlScore;
    draftState.data.il_score_growth = IlScoreGrowth;
    draftState.data.optx_score_growth = scoreGrowth;
    draftState.data.score_growth = scoreGrowth?.toString() || null;
  }
};

const updateCompanyTrendLinesReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<TrendingLinesUpdate, any>
> = (draftState, action) => {
  const { employeeCountTrends, optxScoreTrends } = action.payload;

  if (draftState.data) {
    if (employeeCountTrends) draftState.data.employee_count_trends = employeeCountTrends;
    if (optxScoreTrends) draftState.data.optx_score_trends = optxScoreTrends;
  }
};

const updateCompanyEmployeeGrowthPercentageReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<EmployeeGrowthPercentage, any>
> = (draftState, action) => {
  const { empGrowthPercent } = action.payload;

  if (draftState.data) {
    draftState.data.emp_growth_percent = empGrowthPercent;
  }
};

const updateVerifiedSoftwareSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<UpdateVerifyCompanyPayload>
> = (draftState, action) => {
  const { companyId, value: isVerified } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.is_software_verified = isVerified || false;
  }
};

const addDealTeamSuccessReducer: CaseReducer<CompanyProfileState, PayloadAction<EditDealTeam>> = (
  draftState,
  action
) => {
  const { companyId, membersName } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.deal_team = membersName === null ? [] : membersName;
  }
};

const addSeniorDealTeamLeadSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<EditSeniorDealTeamLead>
> = (draftState, action) => {
  const { companyId, seniorDealTeamLeadList } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.senior_deal_team_lead =
      seniorDealTeamLeadList === null ? [] : seniorDealTeamLeadList;
  }
};

const addCompanyPortfolioSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<{ company_id: number; is_portfolio?: boolean }>
> = (draftState, action) => {
  const { company_id: companyId, is_portfolio: isPortfolio } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.is_portfolio = isPortfolio ?? false;
    draftState.data.is_platform = isPortfolio ?? false;
    // its for tags. is_portfolio and is_platform used for the same thing.
  }
};

const addCompanyEditFieldsSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<EditFieldSuccess>
> = (draftState, action) => {
  const {
    fieldName,
    companyId,
    value,
    score,
    scoreGrowth,
    isPartner,
    companyOwnerData,
    ilScore,
    ilScoreGrowth,
    defaultOptxScore,
    isPsgCompany,
    location,
  } = action.payload;
  const optionSelected = (value as UpdateFieldsPayload).value as SelectOption;
  const fieldKeyValue = (value as UpdateFieldsPayload).value;
  const fieldKey = (value as UpdateFieldsPayload).fieldKey;
  const rationale = (value as UpdateFieldsPayload).rationale;
  const useParentCompany = (value as UpdateFieldsPayload).value as CustomValue;

  if (draftState.data && draftState.data.company_id === companyId) {
    if (defaultOptxScore === 'il') {
      if (ilScore !== undefined) {
        draftState.data.il_optx_score = ilScore;
      }

      if (ilScoreGrowth !== undefined) {
        draftState.data.il_score_growth = ilScoreGrowth as number;
      }
    }

    if (typeof fieldName === 'string' && fieldName !== 'PSG Platform' && fieldName !== 'Add-on') {
      draftState.data.score = typeof score === 'number' ? score : draftState.data.score;
      draftState.data.optx_score_growth = scoreGrowth || Number(draftState.data.score_growth);
    } else if (Array.isArray(fieldName)) {
      draftState.data.score = typeof score === 'number' ? score : draftState.data.score;
      draftState.data.optx_score_growth = scoreGrowth || Number(draftState.data.score_growth);
    }

    if (!Array.isArray(fieldName)) {
      if (fieldName === 'Software Company') {
        draftState.data.is_software =
          ((value as UpdateFieldsPayload).value as unknown as boolean) || false;

        draftState.data.is_software_rationale = rationale as string;

        if (isPartner) {
          draftState.data.is_software_verified = false;
        } else {
          draftState.data.is_software_verified = true;
        }

        draftState.data.score = score || draftState.data.score;
        draftState.data.optx_score_growth = scoreGrowth || Number(draftState.data.score_growth);
      }

      if (fieldName === 'PSG Platform') {
        draftState.data.is_portfolio =
          ((value as UpdateFieldsPayload).value as unknown as boolean) ?? false;
        draftState.data.is_platform =
          ((value as UpdateFieldsPayload).value as unknown as boolean) ?? false;
        // its for tags. is_portfolio and is_platform used for the same thing.
      }

      if (fieldName === 'Next Steps') {
        draftState.data.next_steps =
          optionSelected.label === 'None' ? DEFAULT_EMPTY_VALUE : optionSelected.label;
        draftState.data.next_steps_opinion = (value as UpdateFieldsPayload).extraValues
          ? ((value as UpdateFieldsPayload).extraValues as string[])[0]
          : null;
      }

      if (fieldName === 'PSG Fit') {
        const payloadValue = (value as UpdateFieldsPayload).value as unknown as boolean;

        draftState.data.is_interesting = payloadValue;
        draftState.data.interesting_rationale = payloadValue ? null : (rationale as string);

        draftState.data.score = score || draftState.data.score;
        draftState.data.optx_score_growth = scoreGrowth || Number(draftState.data.score_growth);
      }

      if (fieldName === 'Active on Pipeline') {
        draftState.data.active = (value as UpdateFieldsPayload).value as unknown as boolean;
      }

      if (fieldName === 'OPS Team') {
        const opsMembersName = ((value as UpdateFieldsPayload).value as SelectOption<string>[]).map(
          members => members.label
        );
        draftState.data.ops_team = opsMembersName;
      }

      if (fieldName === 'Deal Team') {
        const dealTeamMembersName = (
          (value as UpdateFieldsPayload).value as SelectOption<string>[]
        ).map(members => members.label);
        draftState.data.deal_team = dealTeamMembersName;
      }

      if (MONTH_DATE_YEAR_FORMAT_FIELDS.includes(fieldName)) {
        draftState.data[fieldKey as 'ic_status_date' | 'expected_close_date'] =
          (fieldKeyValue as string) === null
            ? DEFAULT_SENIOR_ADVISOR_VALUE
            : moment(fieldKeyValue as string).format(ISO_DATE_FORMAT);
      }

      if (fieldName === 'In Business') {
        draftState.data.is_in_business = (value as UpdateFieldsPayload).value as unknown as boolean;
      }

      if (fieldName === 'Company Owner') {
        if (companyOwnerData) {
          draftState.data.company_owner =
            companyOwnerData.company_owner_name === null
              ? DEFAULT_COMPANY_OWNER_VALUE
              : companyOwnerData.company_owner_name;
          draftState.data.company_owner_id =
            companyOwnerData.company_owner_id !== null ? +companyOwnerData.company_owner_id : null;
          draftState.data.owner_img = companyOwnerData.company_owner_img;
        } else {
          draftState.data.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
          draftState.data.company_owner_id = null;
          draftState.data.owner_img = null;
        }
      }

      if (fieldName === 'Sub-Sector') {
        draftState.data.sub_sector = optionSelected.label;
      }

      if (fieldName === 'Sector') {
        draftState.data.sector = optionSelected.label;
      }

      if (fieldName === 'Company Type') {
        draftState.data.company_type = optionSelected.label;
      }

      if (fieldName === 'Pipeline Rank') {
        draftState.data.pipeline_rank = Number(optionSelected.label);
      }

      if (fieldName === 'Senior Advisor Thesis') {
        draftState.data.senior_advisor_thesis = optionSelected.label;
      }

      if (fieldName === 'Senior Deal Team Lead') {
        const seniorDealTeamList = (
          (value as UpdateFieldsPayload).value as SelectOption<string>[]
        ).map(members => members.label);
        draftState.data.senior_deal_team_lead = seniorDealTeamList;
      }

      if (fieldName === 'Lead Source') {
        draftState.data.lead_source = optionSelected.label;
      }

      if (fieldName === 'Stage') {
        draftState.data.stage = optionSelected.label;
        draftState.data.is_psg_company = isPsgCompany as boolean;

        if (showRationaleForStage(optionSelected.label)) {
          draftState.data.stage_rationale = rationale as string;
        } else {
          draftState.data.stage_rationale = null;
        }
      }

      if (fieldName === 'Fund') {
        draftState.data.fund = optionSelected.label;
        draftState.data.is_psg_company = isPsgCompany as boolean;
      }

      if (fieldName === 'Opportunity Type') {
        draftState.data.opportunity_type = optionSelected.label;
      }

      if (fieldName === 'Use Parent Company') {
        draftState.data.use_parent_company = useParentCompany as unknown as boolean;
      }

      if (fieldName === 'AI / ML') {
        draftState.data.is_ai_ml = fieldKeyValue as boolean | '-';
      }

      if (fieldKey === 'num_employees') {
        draftState.data.score = score as number;
        draftState.data[fieldKey] = fieldKeyValue as number;
      }

      if (USER_VALUES_FIELDS.includes(fieldName) && fieldKey) {
        if (fieldKey === 'capital_raised' && fieldKeyValue === null) {
          draftState.data.capital_raised = DEFAULT_EMPTY_VALUE as string;
        } else if (fieldKey !== 'ebitda_list' && fieldKey !== 'revenue_list') {
          draftState.data[fieldKey] = fieldKeyValue as never;
        }

        if (fieldKey === 'last_raised_amount') {
          draftState.data.last_round = (value as UpdateFieldsPayload).lastRoundData
            ?.last_round as string;
          draftState.data.last_round_id = (value as UpdateFieldsPayload).lastRoundData
            ?.last_round_id as number;
          draftState.data.last_investors = (value as UpdateFieldsPayload).lastRoundData
            ?.last_investors as string[];
          draftState.data.raise_date = (value as UpdateFieldsPayload).lastRoundData
            ?.raise_date as string;

          if ((value as UpdateFieldsPayload).lastRoundData?.parentcompany) {
            let data: string;
            if ((value as UpdateFieldsPayload).lastRoundData?.parentcompany === 'blank') data = '';
            else data = (value as UpdateFieldsPayload).lastRoundData?.parentcompany as string;

            draftState.data.parentcompany = data;
          } else draftState.data.parentcompany = '';
        }

        if (fieldKey === 'ebitda_list' || fieldKey === 'revenue_list') {
          updateFinancialValues(draftState.data, fieldKey, fieldKeyValue as CustomValue);
        }
      }

      if (fieldName === 'Product Category') {
        const newCategories = ((value as UpdateFieldsPayload).value as string)
          .split(',')
          .map(item => ({
            category: item,
            is_valid: true,
          }));

        const previousCategories = [...(draftState.data.product_category as ProductCategory[])];

        const combinedArray = newCategories.map(value => {
          const duplicateValue = previousCategories.find(item => item.category === value.category);

          return duplicateValue !== undefined ? duplicateValue : value;
        });

        draftState.data.product_category = ['blank'].includes(
          (value as UpdateFieldsPayload).value as string
        )
          ? null
          : combinedArray;
      }

      if (fieldName === 'Sub Vertical') {
        draftState.data.sub_vertical = (value as UpdateFieldsPayload).value as string[];
      }

      if (fieldName === 'Date Presented') {
        draftState.data.date_presented = fieldKeyValue as string[];
      }

      if (fieldName === 'Add-on') {
        draftState.data.addon = ((value as UpdateFieldsPayload).value as CustomValue)
          .optionalValue as string;
        draftState.data.addon_bool = ((value as UpdateFieldsPayload).value as CustomValue)
          .value as boolean;
        draftState.data.addon_company_id = ((value as UpdateFieldsPayload).value as CustomValue)
          .additionalValue as number;
      }

      if (fieldName === 'Linkedin Profile') {
        draftState.data.linkedin = fieldKeyValue as string;
      }

      if (fieldName === 'Website Status') {
        draftState.data.url_status = fieldKeyValue ? 'active' : 'not active';
      }

      if (fieldName === 'Location') {
        draftState.data.location = location?.toString() as string;
      }
    } else {
      if (fieldName.includes('Stage')) {
        const optionSelected = (value as MultipleFieldsValue).value.Stage;
        draftState.data.stage = optionSelected.label;
        draftState.data.is_psg_company = isPsgCompany as boolean;

        if (showRationaleForStage(optionSelected.label)) {
          draftState.data.stage_rationale = rationale as string;
        } else {
          draftState.data.stage_rationale = null;
        }
      }

      if (fieldName.includes('Company Owner')) {
        if (companyOwnerData) {
          draftState.data.company_owner =
            companyOwnerData.company_owner_name === null
              ? DEFAULT_COMPANY_OWNER_VALUE
              : companyOwnerData.company_owner_name;
          draftState.data.company_owner_id =
            companyOwnerData.company_owner_id !== null ? +companyOwnerData.company_owner_id : null;
          draftState.data.owner_img = companyOwnerData.company_owner_img;
        } else {
          draftState.data.company_owner = DEFAULT_COMPANY_OWNER_VALUE;
          draftState.data.company_owner_id = null;
          draftState.data.owner_img = null;
        }
      }

      if (fieldName.includes('Pipeline Rank')) {
        const optionSelected = (value as MultipleFieldsValue).value['Pipeline Rank'];

        draftState.data.pipeline_rank = Number(optionSelected.label);
      }
    }
  }

  if (draftState.data) {
    if (score !== undefined) {
      draftState.data.score = score as number;
    }

    if (scoreGrowth !== undefined) {
      if (scoreGrowth === null) {
        draftState.data.score_growth = null;
        draftState.data.optx_score_growth = null;
      } else {
        draftState.data.score_growth = scoreGrowth?.toString() as string;
        draftState.data.optx_score_growth = scoreGrowth;
      }
    }
  }

  if (draftState.data && draftState.data.company_id === companyId) {
    if (fieldName === 'Location') {
      draftState.data.location = location?.toString() as string;
    }

    if (fieldName === 'Year Founded') {
      draftState.data.year_founded = optionSelected.value.toString();
    }
  }
};

const addCompanyTagSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<SuccessCompanyTag>
> = (draftState, action) => {
  const { companyId, ...tag } = action.payload;

  if (draftState.data) {
    // Add empty list if is null.
    if (!draftState.data.specialties) {
      draftState.data.specialties = [];
    }

    draftState.data.specialties.push(tag);
  }
};

const deleteCompanyTagSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<DeleteTagPayload>
> = (draftState, action) => {
  const { id } = action.payload;

  if (draftState.data && draftState.data.specialties) {
    const index = draftState.data.specialties.findIndex(item => item.tag_id === id);
    const removedTagName: string | undefined = draftState.data.specialties.find(
      item => item.tag_id === id
    )?.tag;

    if (index !== -1) {
      draftState.data.specialties.splice(index, 1);
      const companyTags = draftState.data.cs_tags ? draftState.data.cs_tags.split('|') : [];

      if (removedTagName && companyTags.includes(removedTagName)) {
        const tagNameIndex = companyTags.indexOf(removedTagName);
        companyTags.splice(tagNameIndex, 1);
        draftState.data.cs_tags = companyTags.join('|');
      }
    }
  }
};

export const addCompanyToListSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<AddCompanyToWatchList>
> = (draftState, action) => {
  const { watchLists, companyId } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    let companyData = { ...draftState.data };

    if (draftState.data) {
      watchLists.forEach(list => {
        if (list.owner_name === action.payload.ownerName || list.origin === 'Equity Touch') {
          companyData = createCompanyToListSuccess(
            companyData as CompanyProfile,
            list
          ) as CompanyProfile;
        }
      });
    }

    draftState.data = companyData as CompanyProfile;
  }
};

export const deleteCompanyToListSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<DeleteCompanyFromWatchList>
> = (draftState, action) => {
  const { listId } = action.payload;

  const companyData = deleteCompanyToListSuccess(draftState.data as Company, listId);
  draftState.data = companyData as CompanyProfile;
};

export const createCompanyToListSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<{
    companyId: number;
    list: CompanyWatchList;
  }>
> = (draftState, action) => {
  const { list } = action.payload;

  const companyData = createCompanyToListSuccess(draftState.data as Company, list);
  draftState.data = companyData as CompanyProfile;
};

const updateCompanyDatePresentedSuccessReducer: CaseReducer<CompanyProfileState> = (
  draftState,
  action
) => {
  if (draftState.data) {
    draftState.data.date_presented = action.payload;
  }
};

const updateCompanyUserValuesSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<CompanyUserValue>
> = (draftState, action) => {
  if (draftState.data) {
    let financialListUpdated: FinancialFieldType[] = [];
    const { fieldKey, value, lastRoundData, financialList, year } = action.payload;

    if (financialList) {
      financialListUpdated = financialList?.filter((item: FinancialFieldType) => {
        return item.value !== DEFAULT_EMPTY_VALUE;
      });
      (draftState.data[fieldKey] as FinancialFieldType[]) = financialListUpdated;
    } else if (fieldKey === 'last_raised_amount') {
      draftState.data[fieldKey] = Number(value);
      draftState.data.last_round = lastRoundData?.last_round as string;
      draftState.data.last_round_id = lastRoundData?.last_round_id as number;

      if (lastRoundData?.parentcompany) {
        let data: string;
        if (lastRoundData?.parentcompany === 'blank') data = '';
        else data = lastRoundData.parentcompany;

        draftState.data.parentcompany = data;
      } else draftState.data.parentcompany = '';
    } else if (value === null) {
      (draftState.data[fieldKey] as null) = null;
    } else if (typeof value === 'number') {
      (draftState.data[fieldKey] as number) = Number(value);
    } else {
      (draftState.data[fieldKey] as string) = value.toString();
    }

    if (financialList && year) {
      if (fieldKey === 'ebitda_list') {
        draftState.data.ebitda_source_db = financialListUpdated.length === 0 ? '' : 'pi';
      } else if (fieldKey === 'revenue_list') {
        draftState.data.last_rev_source_db = financialListUpdated.length === 0 ? '' : 'pi';
      }
    }
  }
};

const editUserContactSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  if (draftState.data) {
    const contacts = draftState.data!.contacts;
    const contactDetails = action.payload;
    const index = contacts.findIndex(contact => contact.person_id === contactDetails.person_id);
    const primaryContact = contacts.find(contact => contact.primary_contact);

    // check for existing primary contact and reset it,
    // if new contact is set as primary
    if (
      primaryContact &&
      contactDetails.primary_contact &&
      primaryContact.person_id !== contactDetails.person_id
    ) {
      primaryContact.primary_contact = false;
    }

    if (index !== -1) {
      contacts[index] = {
        ...contacts[index],
        ...contactDetails,
        is_edited: true,
        additional_phones: contactDetails.phones || null,
        // There is an issue with name.
        // If initially there is no first_name or last_name they are computed from full_name.
        // On edit only first_name and last name are updated, but no full_name so we recreate it based on new values.
        full_name: `${contactDetails.first_name} ${contactDetails.last_name}`,
      };
    }
  }
};

const addContactSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<Partial<CompanyUserContact>>
> = (draftState, action) => {
  if (draftState.data) {
    const companyContacts = draftState.data?.contacts;
    const newContact = {
      ...(action.payload as CompanyUserContact),
      additional_phones: action.payload.phones as ContactPhone[],
      primary_contact: !!action.payload.primary_contact,
    };
    const mappedContacts = action.payload.primary_contact
      ? companyContacts?.map(contact => ({ ...contact, primary_contact: false }))
      : companyContacts;

    if (draftState.data && mappedContacts) {
      draftState.data.contacts = [...mappedContacts, ...[newContact]];
    }

    draftState.data.has_contacts = true;
  }
};

const updateCompanyETDataReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<UpdateCompanyET>
> = (draftState, action) => {
  // reset data to force endpoint call for updating profile page
  draftState.data = null;
};

const addFavoriteSuccessReducer: CaseReducer<CompanyProfileState> = draftState => {
  if (window.location.pathname.includes('profile') && draftState.data) {
    draftState.data.is_in_fav_list = true;
  }
};

const deleteFavoriteSuccessReducer: CaseReducer<CompanyProfileState> = draftState => {
  if (window.location.pathname.includes('profile') && draftState.data) {
    draftState.data.is_in_fav_list = false;
  }
};

const loadingCompletedReducer: CaseReducer<CompanyProfileState> = draftState => {
  draftState.loading = false;
};

const resetCompanyProfileReducer: CaseReducer<CompanyProfileState> = draftState => {
  if (draftState.data) {
    draftState.data = null;
  }
};

const addDateFieldsSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<EditDateFields>
> = (draftState, action) => {
  const { companyId, ...rest } = action.payload;
  const fieldKey = Object.keys(rest)[0];
  const value = Object.values(rest)[0];

  if (fieldKey && draftState.data && draftState.data.company_id === companyId) {
    draftState.data[fieldKey as 'ic_status_date' | 'expected_close_date'] =
      value === null
        ? DEFAULT_SENIOR_ADVISOR_VALUE
        : moment(value as string, 'DD.MM.YYYY').format(ISO_DATE_FORMAT);
  }
};

const addCompanyActiveSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<CompanyActivePayload>
> = (draftState, action) => {
  const { companyId, active } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.active = active || false;
  }
};

const addCompanyFundSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<EditCompanyFund>
> = (draftState, action) => {
  const { companyId, fundName } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.fund = fundName;
  }
};

const addOpportunityTypeSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<EditOpportunityType>
> = (draftState, action) => {
  const { companyId, opportunityTypeName, scoreValues } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    if (scoreValues) {
      draftState.data.score = scoreValues.optx_score;
      draftState.data.score_growth = scoreValues.optx_score_growth?.toString() || null;
      draftState.data.il_score_growth = scoreValues.il_optx_score_growth;
      draftState.data.il_optx_score = scoreValues.il_optx_score;
    }

    draftState.data.opportunity_type =
      opportunityTypeName === null ? DEFAULT_OPPORTUNITY_TYPE : opportunityTypeName;
  }
};

const sendOptxScoreRationaleSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<OptxScoreRationale>
> = (draftState, action) => {
  const {
    companyId,
    rationale,
    defaultScore,
    optxScoreVerified,
    optxScoreRationaleReason,
    optxScoreRationaleReasonType,
  } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    if (defaultScore === 'us') {
      draftState.data.optx_score_rationale = rationale;
      draftState.data.optx_score_verified = optxScoreVerified;
      draftState.data.optx_score_rationale_reason = optxScoreRationaleReason;
      draftState.data.optx_score_rationale_reason_type = optxScoreRationaleReasonType;
    }

    if (defaultScore === 'il') {
      draftState.data.il_optx_score_rationale = rationale;
      draftState.data.il_optx_score_verified = optxScoreVerified;
      draftState.data.il_optx_score_rationale_reason = optxScoreRationaleReason;
      draftState.data.il_optx_score_rationale_reason_type = optxScoreRationaleReasonType;
    }
  }
};

export const addCompanyReviewSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<AddCompanyReviewSuccess>
> = (draftState, action) => {
  const { reviewed, reviewedBy, reviewedDate, companyId } = action.payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    draftState.data.company_reviewed_by = reviewedBy;
    draftState.data.company_reviewed_date = reviewedDate;
    draftState.data.is_company_reviewed = reviewed;
  }
};

const updateOpportunityPresentationSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<OpportunityPresentation>
> = (draftState, { payload }) => {
  if (draftState.data) {
    if ('score' in payload) {
      draftState.data.score = payload.score as number;
    }

    if ('il_optx_score' in payload) {
      draftState.data.il_optx_score = payload.il_optx_score as number;
    }

    if (payload.score_growth) {
      draftState.data.score_growth = (payload.score_growth as number).toString();
      draftState.data.optx_score_growth = payload.score_growth as number;
    }

    if (payload.il_score_growth) {
      draftState.data.il_score_growth = payload.il_score_growth as number;
      draftState.data.il_optx_score_growth = payload.il_score_growth as number;
    }

    if (payload.financial) {
      if (payload.financial.revenue) {
        draftState.data.revenue_list = payload.financial.revenue
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }

      if (payload.financial.ebitda) {
        draftState.data.ebitda_list = payload.financial.ebitda
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }

      if (payload.financial.gm) {
        draftState.data.gross_margin_list = payload.financial.gm
          .map(item => ({
            estimated: item.estimated,
            value: item.value,
            year: item.year,
          }))
          .sort((a, b) => (b.year as number) - (a.year as number));
      }
    }

    if (
      payload.deal_preparation &&
      typeof payload.deal_preparation.next_steps.value !== 'boolean' &&
      !Array.isArray(payload.deal_preparation.next_steps.value)
    ) {
      draftState.data.next_steps = payload.deal_preparation.next_steps.value;
    }

    if (payload.deal_preparation) {
      draftState.data.next_steps_opinion = payload.deal_preparation.next_steps_opinion ?? null;
    }

    if (payload.funding) {
      draftState.data.raise_date = payload.funding.last_round_date as string;

      draftState.data.last_raised_amount = payload.funding.last_round_amount;

      draftState.data.last_round = payload.funding.last_round.value as string;

      draftState.data.capital_raised = payload.funding.capital_raised;

      draftState.data.parentcompany = payload.funding.acquiring_company;

      if (payload.funding.last_investors) {
        draftState.data.last_investors = payload.funding.last_investors || [];
      }
    }
  }
};

const updateCompanyInfoSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  if (draftState.data && draftState.data.company_id === payload.company_id) {
    if (payload.general_info) {
      if (payload.general_info.primary_contact) {
        const contacts = draftState.data.contacts.map(contact => {
          if (contact.person_id === payload.general_info?.primary_contact) {
            return { ...contact, primary_contact: true };
          } else {
            return { ...contact, primary_contact: false };
          }
        });
        draftState.data.contacts = contacts;
      }

      if (payload.general_info.num_employees) {
        draftState.data.num_employees = payload.general_info.num_employees;
      }

      if (payload.general_info.sub_sector) {
        draftState.data.sub_sector = payload.general_info.sub_sector.name;
      }

      if (payload.general_info.product_category) {
        const updatedProdCat = payload.general_info.product_category.map(
          (prodCat: SelectOption) => {
            return {
              category: prodCat.label,
              // @ts-ignore
              is_valid: 'isValid' in prodCat ? prodCat.isValid : prodCat.is_valid,
            };
          }
        );
        draftState.data.product_category = updatedProdCat;
      }
    }

    if (payload.funding) {
      if (payload.funding.capital_raised) {
        draftState.data.capital_raised = payload.funding.capital_raised;
      }

      if (payload.funding.last_round) {
        draftState.data.last_round =
          payload.funding.last_round.label &&
          payload.funding.last_round.label !== DEFAULT_EMPTY_VALUE
            ? payload.funding.last_round.label
            : null;
        draftState.data.last_round_id =
          payload.funding.last_round.value &&
          payload.funding.last_round.value.toString() !== DEFAULT_EMPTY_VALUE
            ? payload.funding.last_round.value
            : null;
      }

      if (payload.funding.next_steps) {
        draftState.data.next_steps = payload.funding.next_steps;
      }

      if (payload.funding.acquiring_company) {
        draftState.data.parentcompany = payload.funding.acquiring_company;
      }

      if (payload.funding.last_round_date) {
        draftState.data.raise_date = payload.funding.last_round_date;
      }

      if (payload.funding.last_round_amount) {
        draftState.data.last_raised_amount = payload.funding.last_round_amount;
      }

      if (payload.funding.last_investors) {
        draftState.data.last_investors = payload.funding.last_investors || [];
      }
    }

    if (payload.financials) {
      if (payload.financials.ebitda) {
        draftState.data.ebitda_list = payload.financials.ebitda;
      }

      if (payload.financials.revenue) {
        draftState.data.revenue_list = payload.financials.revenue;
      }
    }
  }
};

const addTouchToCompanySuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<CompanyTouchSuccessResponse>
> = (draftState, { payload }) => {
  const {
    companyid: companyId,
    next_touch_date: nextTouchDate,
    last_touch_date: lastTouchDate,
    iscomplete: isComplete,
  } = payload;

  if (draftState.data && draftState.data.company_id === companyId) {
    if (isComplete) {
      draftState.data.last_touch_date = lastTouchDate;
    } else {
      draftState.data.next_touch_date = nextTouchDate;
    }
  }
};

const updateAllCompanyEditAllInfoSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<Partial<TouchesFinancialPayload>>
> = (draftState, { payload }) => {
  if (draftState.data && draftState.data.company_id === payload.company_id) {
    draftState.data = {
      ...draftState.data,
      ...payload,
    };
  }
};

const companyFundingRoundsSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<CompanyFundingRounds>
> = (draftState, action) => {
  if (draftState.data) {
    const { total_raised, last_round } = action.payload;

    // @ts-ignore
    draftState.data.capital_raised = total_raised;

    if (total_raised !== null) {
      draftState.data.is_capital_raise_has_unknown = total_raised.includes('(plus Unknown)');
    }

    if (last_round?.amount !== null) {
      draftState.data.last_raised_amount = last_round?.amount as unknown as number;
    }
  }
};

const loadingStartedReducer: CaseReducer<CompanyProfileState> = draftState => {
  draftState.loading = true;
};

const aiDescriptionSuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<AiDescriptionResponse>
> = (draftState, action) => {
  const { description } = action.payload;

  if (draftState.data) {
    const stateDescriptions = current(draftState.data?.descriptions);
    draftState.data.company_description = description;

    if (stateDescriptions && 'AI' in stateDescriptions) {
      draftState.data.descriptions.AI = description;
    } else {
      draftState.data.descriptions = { AI: description, ...stateDescriptions };
    }
  }

  draftState.loading = false;
};

const aiRelationshipSummarySuccessReducer: CaseReducer<
  CompanyProfileState,
  PayloadAction<AiRelationshipSummaryResponse>
> = (draftState, action) => {
  if (draftState.data) {
    draftState.data.highlights = action.payload.highlights;
  }

  draftState.loading = false;
};

const reducer = createReducer(initialState, builder =>
  builder
    .addCase(actions.fetchCompanyProfile, fetchProfileReducer)
    .addCase(actions.fetchCompanyProfileSuccess, fetchProfileSuccessReducer)
    .addCase(actions.setSelectedDescription, setSelectedDescriptionReducer)
    .addCase(actions.fetchCompanyProfileFail, fetchProfileFailReducer)
    .addCase(actions.resetCompanyProfile, resetCompanyProfileReducer)
    .addCase(searchActions.loadCompaniesByFilter, fetchReducer)
    .addCase(searchActions.loadCompaniesByFilterCompleted, loadingCompletedReducer)
    .addCase(searchActions.loadSuggestedSearch, fetchReducer)
    .addCase(suggestedSearchActions.fetchSuggestedSearchesSuccess, loadingCompletedReducer)
    .addCase(equityTouchActions.updateCompanyETData, updateCompanyETDataReducer)
    .addCase(
      inBusinessActions.addCompanyInBusinessEvaluationSuccess,
      addCompanyInBusinessEvaluationSuccessReducer
    )
    .addCase(editFieldsActions.updateFieldsSuccess, addCompanyEditFieldsSuccessReducer)
    .addCase(editAllInfoActions.updateCompanyEditAllInfoSuccess, updateCompanyInfoSuccessReducer)
    .addCase(
      editAllInfoActions.updateAllCompanyEditAllInfoSuccess,
      updateAllCompanyEditAllInfoSuccessReducer
    )
    .addCase(
      updateCompanyInfoActions.updateTouchCompanyInfoSuccess,
      updateCompanyInfoSuccessReducer
    )
    .addCase(updateCompanyInfoActions.addTouchToCompanySuccess, addTouchToCompanySuccessReducer)
    .addCase(editFieldsActions.updateVerifyCompanySoftware, updateVerifiedSoftwareSuccessReducer)
    .addCase(UPDATE_COMPANY_OPTX_SCORE, updateCompanyOptxScoreSuccessReducer)
    .addCase(portfolioActions.updateCompanyPortfolioSuccess, addCompanyPortfolioSuccessReducer)
    .addCase(
      seniorDealTeamLeadActions.addSeniorDealTeamLeadSuccess,
      addSeniorDealTeamLeadSuccessReducer
    )
    .addCase(DealTeamActions.addDealTeamMember, addDealTeamSuccessReducer)
    .addCase(tagsActions.addCompanyTagSuccess, addCompanyTagSuccessReducer)
    .addCase(tagsActions.deleteCompanyTagSuccess, deleteCompanyTagSuccessReducer)
    .addCase(
      datePresentedActions.updateCompanyDatePresentedSuccess,
      updateCompanyDatePresentedSuccessReducer
    )
    .addCase(
      userValuesActions.updateCompanyUserValuesSuccess,
      updateCompanyUserValuesSuccessReducer
    )
    .addCase(userValuesActions.updateCompanyTrendingLines, updateCompanyTrendLinesReducer)
    .addCase(
      userValuesActions.updateCompanyEmployeeGrowthPercentage,
      updateCompanyEmployeeGrowthPercentageReducer
    )
    .addCase(primaryContactsActions.editPrimaryContactSuccess, editUserContactSuccessReducer)
    .addCase(bulkSalesloftActions.addContactSuccess, addContactSuccessReducer)
    .addCase(favoritesActions.addFavoriteSuccess, addFavoriteSuccessReducer)
    .addCase(favoritesActions.deleteFavoriteSuccess, deleteFavoriteSuccessReducer)
    // Company Profile
    .addCase(favoriteListsActions.addCompanyToListSuccess, addCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.deleteCompanyFromListSuccess, deleteCompanyToListSuccessReducer)
    .addCase(favoriteListsActions.createFavoriteListSuccess, createCompanyToListSuccessReducer)
    .addCase(favoriteListsPopupActions.addCompanyToListPopupSuccess, addCompanyToListSuccessReducer)
    .addCase(
      favoriteListsPopupActions.deleteCompanyFromListPopupSuccess,
      deleteCompanyToListSuccessReducer
    )
    .addCase(
      favoriteListsPopupActions.createFavoriteListPopupSuccess,
      createCompanyToListSuccessReducer
    )
    .addCase(activeActions.addCompanyActiveSuccess, addCompanyActiveSuccessReducer)
    .addCase(dateFieldsActions.addDateFieldsSuccess, addDateFieldsSuccessReducer)
    .addCase(fundActions.addFundSuccess, addCompanyFundSuccessReducer)
    .addCase(
      companyOportunityTypeActions.addOpportunityTypeSuccess,
      addOpportunityTypeSuccessReducer
    )
    .addCase(
      researchRationaleActions.submitResearchRationaleSuccess,
      submitResearchRationaleSuccessReducer
    )
    .addCase(sendOptxScoreRationaleSuccess, sendOptxScoreRationaleSuccessReducer)
    .addCase(companyReviewActions.addCompanyReviewSuccess, addCompanyReviewSuccessReducer)
    .addCase(
      opportunityPresentationActions.addFinancialYearSuccess,
      updateOpportunityPresentationSuccessReducer
    )
    .addCase(
      opportunityPresentationActions.updateOpportunityPresentationSuccess,
      updateOpportunityPresentationSuccessReducer
    )
    .addCase(fundingRoundActions.getCompanyFundingRoundsSuccess, companyFundingRoundsSuccessReducer)
    .addCase(actions.opinionAiDescription, loadingStartedReducer)
    .addCase(actions.opinionAiDescriptionSuccess, aiDescriptionSuccessReducer)
    .addCase(actions.opinionAiDescriptionFail, loadingCompletedReducer)
    .addCase(actions.regenerateAiDescription, loadingStartedReducer)
    .addCase(actions.regenerateAiDescriptionFail, loadingCompletedReducer)
    .addCase(actions.regenerateAiDescriptionSuccess, aiDescriptionSuccessReducer)
    .addCase(actions.aiRelationshipSummary, loadingStartedReducer)
    .addCase(actions.aiRelationshipSummarySuccess, aiRelationshipSummarySuccessReducer)
    .addCase(actions.aiRelationshipSummaryFail, loadingCompletedReducer)
);

export default reducer;
