import styled from 'styled-components';
import Icon from '@components/common/Icon';

export const NewTabIcon = styled(Icon).attrs(props => ({
  iconName: 'chrome-new-tab',
}))`
  width: 20px;
  margin-left: 5px;
`;

const ChromeExtension = styled.div`
  width: 630px;
  height: 100vh;
  position: relative;
`;

export default {
  ChromeExtension,
};
