import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// styles
import './SearchHeader.styles.scss';
// utils
import { mapTagsToNamesAndOperators } from '@utils/filters/mapTagsToNamesAndOperators';
// redux
import { selectors as searchSelectors } from '@redux/company-outreach/search';
import { actions as searchActions } from '@features/grid/search';
import { selectors as fullScreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as searchesSelectors } from '@redux/company/saved-searches';
import { selectors as gridSearchSelectors } from '@features/grid/search';
import { selectors as viewSelectors } from '@features/grid/view';
// components
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import CompanySearch from './CompanySearch';
import FilterTags from './FilterTags';
import CompanySearchDropdown from './CompanySearchDropdown';
import ExportToCSV from './CompanySearchDropdown/SearchMenu/ExportToCSV';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';
import ShowFilterButton from '@optx/features/grid/filter/components/ShowFilterButton';
// eslint-disable-next-line max-len
import { default as CombinedSearchFilterTags } from '@optx/components/common/table/TableSavedSearches/cells/company/FilterTags';

const SearchHeader = () => {
  const dispatch = useDispatch();

  const showRestoreIcon = useSelector(searchSelectors.showCustomClearedFilter);
  const customClearedFilter = useSelector(searchSelectors.getCustomClearedFilter);
  const isFullScreen = useSelector(fullScreenSelectors.isFullscreen);

  const savedSearches = useSelector(searchesSelectors.getSavedSearches);
  const view = useSelector(viewSelectors.getView('outreach'));
  const selectedCombinedSearch = savedSearches.find(search => search.unique_id === view.id);
  const listType = useSelector(gridSearchSelectors.getListType('outreach'));

  const filterNames = mapTagsToNamesAndOperators(
    selectedCombinedSearch?.parent_search_ids,
    savedSearches,
    selectedCombinedSearch?.logical_operators
  );

  return (
    <div className="search-header-content">
      <CompanySearch className="antd-search-input" autoComplete="off" />
      {listType === 'combined' ? (
        <CombinedSearchFilterTags
          filterNames={filterNames}
          listType={listType}
          savedSearches={filterNames}
        />
      ) : (
        <FilterTags />
      )}
      <div>
        <ClearSearchButton
          title="Clear All"
          placement="bottom"
          isVisible={showRestoreIcon || listType === 'combined'}
          onClick={() =>
            dispatch(searchActions.clearSearch({ gridKey: 'outreach', data: customClearedFilter }))
          }
        />
        <ShowFilterButton gridKey={'outreach'} style={{ position: 'relative' }} />
      </div>
      <div style={{ position: 'relative', paddingRight: '40px' }}>
        <CompanySearchDropdown>
          <ExportToCSV />
        </CompanySearchDropdown>
        {isFullScreen && <GridFullScreen />}
      </div>
    </div>
  );
};

export default React.memo(SearchHeader);
