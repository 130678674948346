import React, { useEffect, useState } from 'react';
import { Select, Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { FormItem } from 'formik-antd';
// models
import { TouchFieldProps } from './interface';

const { Option } = Select;

const SingleSelectBankerField: React.FC<TouchFieldProps> = ({ field }) => {
  const [, , setBanker] = useField<boolean>(field.id);
  const [selectedValue, setSelectedValue] = useState<string>('');

  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    if (typeof field.value === 'boolean') {
      if (field.value) {
        setSelectedValue('yes');
      } else {
        setSelectedValue('no');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (selectedValue && selectedValue === 'yes') {
      setBanker.setValue(true);
    } else if (selectedValue && selectedValue === 'no') {
      setBanker.setValue(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <>
      <Col span={24} className="grid-spacer"></Col>
      <Col span={8} className={field.id}>
        <Typography.Text>{field.label}</Typography.Text>
        <FormItem name={field.id}>
          <Select
            showSearch
            value={selectedValue}
            onChange={handleChange}
            getPopupContainer={trigger => trigger.parentElement!}
            filterOption={(input: string, option: OptionTypeBase | undefined) => {
              const fullName = option ? `${option?.children.props.children}` : '';

              return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {field.data &&
              field.data.length &&
              field.data?.map((option: OptionTypeBase) => (
                <Option key={option.value as string} value={option.value as string}>
                  <span>{option.label}</span>
                </Option>
              ))}
          </Select>
        </FormItem>
      </Col>
    </>
  );
};

export default React.memo(SingleSelectBankerField);
