import { put, select, takeLatest, fork, call, cancel, cancelled } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary, isEqual } from 'lodash';
import queryString from 'query-string';
import { push } from 'react-router-redux';
import { AxiosResponse } from 'axios';
// models
import Company, { CompanyProfile } from '@models/Company';
import {
  BaseFilter,
  Filter,
  FilterSearchOriginName,
  FilterSource,
  PreselectedFilter,
} from '@models/filters';
import { SearchViewType, UserSettings } from '@models/user';
import { SearchSave, SearchStateData } from '@models/search';
import { SearchPayload, SearchPayloadRequest } from '@models/api/contacts';
import { UpdateUserSettingsPayload } from '@models/api/user';
import { SortByRule } from '@models/table/sorting';
import { PageInfo } from '@models/table/Pagination';
import { GridKeysMain, GridPayloadMain, GetGridSearchData, GridKeys } from '@optx/models/grid';
import { SuccessErrorCallback } from '@models/callback';
import { LoadSuggestedSearch, ResetFilter } from './interfaces';
import { ColumnKeys } from '@models/table/Columns';
import { SearchAutocomplete } from '@models/search-autocomplete';
import { SelectOption } from '@models/Option';
import { ChartBarIntervalKeys } from '@models/charts/data';
import { AddonManagementPayload } from '@optx/redux/company/addon-management/interfaces';
import { TableGridQuery } from '@optx/screens/App/AllReports/interface';
// constants
import {
  COMPANY_DEFAULT_SORT,
  MYCOMPANIES_DEFAULT_SORT_IL,
  MYCOMPANIES_DEFAULT_SORT_US,
  ANALYST_HOME_DEFAULT_SORT_IL,
  ANALYST_HOME_DEFAULT_SORT_US,
} from '@constants/table/sort/defaultSort';
import { LONG_CARD_VIEW_DEFAULT_PAGE_SIZE } from '@constants/pagination';
import routes from '@constants/routes';
// services
import NotificationService from '@services/NotificationService';
import {
  SearchService,
  FilterService,
  SavedSearchesService,
  UserService,
  CompanyService,
} from '@services/api';
// utils
import { mapPreselectedFilters } from '@utils/filters/preselectedValues';
import { sortSimilarCompanies } from '@optx/utils/utils';
import {
  preProcessSearch,
  mapSortQuery,
  isCustomUIView,
  matchView,
  hasFilters,
} from '@utils/search';
import { injectFilter } from '@utils/filters/injectFilter';
import { getRouteAliasFromGridKey } from '@utils/routes';
import { getLocationMainGridKey, getSearchOriginKeyFromGridKey } from '@utils/grid';
import { getCompanyOwner, myCompaniesCardsFilters, processCardList } from '@utils/myCompanies';
import { removeAllParametersFromURL, removeParameterFromURL, updateHistoryState } from '@utils/url';
import { removeTouchFilters } from '../utils/filters';
// redux
import { actions as scrollHistoryActions } from '@features/scroll-history';
import { selectors as advancedSearchSearchSelectors } from '@redux/company/search/search';
import { selectors as profileSelectors } from '@redux/company/profile';
import * as actions from './actions';
import { actions as searchCountActions } from '@features/grid/search-count';
import * as selectors from './selectors';
import {
  initializeLongCardSearchSaga,
  initialCompanySearchSaga,
  searchCompanies,
  searchCompaniesFail,
  updateFilterSettings,
} from './sagasReusable';
import {
  selectors as filterSourcesSelectors,
  actions as filterActions,
} from '@redux/company/filters';
import {
  actions as suggestedSearchActions,
  selectors as suggestedSearchSelectors,
} from '@redux/company/suggested-searches';
import { selectors as viewTypeSelectors } from '@features/grid/view-type';
import { getFiltersQuery, handleTouchFilters } from '@redux/company/search/search/sagasReusable';
import { actions as longCardPaginationActions } from '@features/grid/pagination-long-card';
import {
  selectors as paginationSelectors,
  actions as paginationActions,
} from '@features/grid/pagination';
import {
  selectors as userInformationSelectors,
  actions as userInformationActions,
} from '@redux/user/information';
import { actions as histogramActions } from '@features/histograms/histograms-advanced-search';
import { actions as histogramListsActions } from '@features/histograms/histograms-list-details';
import { getSearch as getSearchSavedSearchSelectors } from '@redux/company/saved-searches/selectors';
import { updateColumns as advancedSearchUpdateColumns } from '@redux/company/search/columns/actions';
import { applyViewSuccess as applyViewSuccessSavedSearchesActions } from '@redux/company/saved-searches/actions';
import {
  getViews as getViewsSavedSearchSelectors,
  getSavedSearches as getSavedSearchesSavedSearchSelectors,
} from '@redux/company/saved-searches/selectors';
import { actions as longCardActions } from '@features/long-card/company-card';
import { selectors as filterSelectors } from '@features/grid/filter';
import {
  actions as listsDetailsActions,
  selectors as listsDetailsSelectors,
} from '@redux/lists/details';
import { actions as columnActions } from '@redux/lists/search/columns';
import { userInfoUpdateSaga, getGridSearchDataSaga } from '@features/grid/state/sagasReusable';
import { selectors as advancedSearchColumnSelectors } from '@redux/company/search/columns';
import { selectors as listsSearchCommonSelectors } from '@redux/lists/search/common';
import { getSourcingOutReachFilters } from '@redux/company-outreach/search/saga';
import { selectors as outreachSearchSelectors } from '@redux/company-outreach/search';
import {
  selectors as outreachFilterSelectors,
  actions as outreachFilterActions,
} from '@redux/company-outreach/filters';
import {
  selectors as myCompaniesFilterCardsSelectors,
  actions as myCompaniesFilterCardsActions,
} from '@optx/redux/my-companies/filters-cards';
import { selectors as myCompaniesSearchSelectors } from '@redux/my-companies/search';
import { actions as myCompaniesSortActions } from '@redux/my-companies/sort';
import { selectors as addonSelectors } from '@redux/company/addon-management';
import { incrementFetchedCount } from '../../fetched-count/state/actions';
import { similarCompaniesSortingAction } from '@optx/redux/company/search/sort/actions';

function* initializeSearch(gridKey: GridKeysMain, tableGridQuery: string) {
  let view: SearchViewType = 'table';

  switch (gridKey) {
    case 'advancedSearch':
      view = yield select(viewTypeSelectors.searchView('advancedSearch'));
      break;

    case 'lists':
      view = yield select(viewTypeSelectors.searchView('lists'));
      break;

    case 'outreach':
      view = yield select(viewTypeSelectors.searchView('outreach'));
      const shouldUpdateSourcingOutReachAnalystInfo: boolean = yield select(
        selectors.shouldUpdateSourcingOutReachAnalystInfo
      );

      if (shouldUpdateSourcingOutReachAnalystInfo) yield fork(getSourcingOutReachFilters);

      break;

    default:
      break;
  }

  yield fork(
    view === 'long-card' ? initializeLongCardSearchSaga : initialCompanySearchSaga,
    gridKey,
    tableGridQuery
  );
}

function* initializeSearchSaga(
  action: PayloadAction<GridPayloadMain<Partial<SearchPayload> | undefined>>
) {
  const { gridKey, data } = action.payload;

  let tableGridQuery = '';
  if (data?.tableGridQuery) tableGridQuery = data.tableGridQuery;

  if (gridKey === 'advancedSearch') {
    const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
    // @ts-ignore
    const res = yield call(SavedSearchesService.getSuggestedSearches);

    if (res.data) {
      yield put(
        suggestedSearchActions.fetchSuggestedSearchesSuccess(
          res.data.lists,
          filterSources,
          mapPreselectedFilters(filterSources, false),
          res.data.total_list_count
        )
      );
    }
  }

  yield call(initializeSearch, gridKey, tableGridQuery);
}

export function* searchWithCountSaga(
  action: PayloadAction<GridPayloadMain<Partial<SearchPayload> | undefined>, any, boolean>
) {
  const { gridKey, data } = action.payload;
  const prevSearchKey: string = yield select(selectors.getSearchKey(gridKey));
  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey);
  const defaultOptxScore: string = yield select(userInformationSelectors.getDefaultScore);
  const isSimilarCompaniesSorting: boolean | undefined = yield select(
    advancedSearchSearchSelectors.isSimilarCompaniesSorting
  );
  let { sortBy, searchKey, originalFilter: filter, view, pagination } = gridSearchData;

  if (data) {
    searchKey = data.searchKey ? preProcessSearch(data.searchKey) : '';

    if (!data.filter) {
      filter = {
        ...filter,
        query: searchKey,
      };
    } else {
      filter = data.filter;
    }
  } else {
    searchKey = preProcessSearch(prevSearchKey);
  }

  // there are two exceptions when sorting needs to be either removed or set to default
  // 1. when making a search with a new search term the request will not include sorting
  // 2. when making a new search right after the one from point 1., without changing the search term
  // the request will include default sorting
  // After the search including new search term the next searches changes will include sorting.

  if (
    (searchKey === '' && searchKey !== prevSearchKey && !sortBy?.length) ||
    (searchKey !== '' && searchKey === prevSearchKey && !sortBy?.length)
  ) {
    if (gridKey === 'myCompanies') {
      sortBy =
        defaultOptxScore === 'us' ? MYCOMPANIES_DEFAULT_SORT_US : MYCOMPANIES_DEFAULT_SORT_IL;
    } else {
      sortBy = COMPANY_DEFAULT_SORT;
    }
  } else if (searchKey !== '' && searchKey !== prevSearchKey) {
    sortBy = [];
  }

  if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);

    // Ensures to filter by the selected list
    filter = {
      ...filter,
      ...(isSourceScrub ? { ss_list_id: listId } : { saved_list_id: listId }),
    };
  }

  if (data?.shouldHandleTouchFilters) filter = yield call(handleTouchFilters, filter);

  if (gridKey === 'outreach') {
    const sourcingOutReachDateFilter: string = yield select(
      outreachFilterSelectors.getSourcingFilterOptionsDateValue
    );

    filter = {
      ...filter,
      ddate: sourcingOutReachDateFilter,
    };
  }

  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  if (data?.shouldResetPageNumber) {
    pagination = {
      pageNumber: 1,
      pageSize: view === 'table' ? pagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
    };
  }

  if (data?.shouldBeAnalystHomePageSorting) {
    sortBy =
      defaultOptxScore === 'us' ? ANALYST_HOME_DEFAULT_SORT_US : ANALYST_HOME_DEFAULT_SORT_IL;
  }

  if (isSimilarCompaniesSorting) {
    const currentOptxScore = defaultOptxScore === 'us' ? 'score' : 'il_optx_score';
    sortBy = [{ id: currentOptxScore, desc: true }];
  }

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
  };

  if (gridKey === 'addons') {
    const additionalFilterQuery: Dictionary<string | (string | number)[]> = yield select(
      addonSelectors.getAdditionalFiltersQuery
    );

    searchData.companyId = data?.companyId;
    searchData.filter = { ...searchData.filter, ...additionalFilterQuery };
  }

  if (gridKey === 'myCompanies') {
    const selectedCard: SearchStateData = yield select(
      myCompaniesFilterCardsSelectors.getSelectedCard
    );
    const subFilter: string = action.meta
      ? ''
      : yield select(myCompaniesFilterCardsSelectors.getSubFilter);

    const savedListId = selectedCard.filter?.saved_list_id
      ? { saved_list_id: selectedCard.filter?.saved_list_id }
      : { saved_list_id: undefined };

    searchData.filter = subFilter
      ? {
          ...selectedCard.filter,
          // @ts-ignore
          ...selectedCard.data[subFilter],
          ...filterQuery,
          ...savedListId,
          source_tag_filter: selectedCard.filter.source_tag_filter,
          ...myCompaniesCardsFilters(selectedCard, {
            nextTouchDate: filterQuery.next_touch_date as any,
            lastTouchDateDays: filterQuery.last_touch_date_days as any,
            stage: filterQuery.stage as any,
          }),
          query: searchKey,
        }
      : {
          ...selectedCard.filter,
          ...filterQuery,
          ...savedListId,
          source_tag_filter: selectedCard.filter.source_tag_filter,
          query: searchKey,
          ...myCompaniesCardsFilters(selectedCard, {
            nextTouchDate: filterQuery.next_touch_date as any,
            lastTouchDateDays: filterQuery.last_touch_date_days as any,
            stage: filterQuery.stage as any,
          }),
        };
  }

  if (gridKey === 'outreach') {
    const analystIdFilter: [string | null, string | null] | SelectOption<string> | undefined =
      yield select(outreachFilterSelectors.getSourcingOptitonsFilterValues('analyst_id'));
    const tableGridQuery: string = yield select(selectors.getTableGridQuery('outreach'));

    const columnNameSourcingOutReachFilter:
      | [string | null, string | null]
      | SelectOption<string>
      | undefined = yield select(
      outreachFilterSelectors.getSourcingOptitonsFilterValues('column_name')
    );

    // @ts-ignore
    const listOfAnalystIds: number[] = analystIdFilter?.map(
      (list: { label: string; value: number }) => list.value
    );

    const sourcingOutReachColumnName = (columnNameSourcingOutReachFilter as SelectOption<string>)
      ?.value;

    searchData.analystId = listOfAnalystIds?.toString();
    searchData.tableGridQuery = tableGridQuery;
    searchData.sourcingOutReachColumnName = sourcingOutReachColumnName;
  }

  try {
    // Only update filters if it is not the first call.
    // This will also allow us to load specific filters from URL in new tab without interfering with main tab settings.
    const isInitialSearch: boolean = yield select(selectors.isInitialSearch(gridKey));

    const userSettings: { [key: string]: number } = yield call(
      userInfoUpdateSaga,
      gridKey,
      searchData.pagination,
      true
    );

    let isUpdatedSessionSettings = isEqual(userSettings, {}) ? !isInitialSearch : userSettings;

    if (gridKey === 'advancedSearch' && data?.isSimilarCompaniesSearch) {
      isUpdatedSessionSettings = true;
      yield put(similarCompaniesSortingAction(true));
    }

    yield call(
      searchCompanies,
      searchData,
      gridKey,
      filter,
      false,
      undefined,
      isUpdatedSessionSettings
    );

    // also make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
    }

    if (view === 'long-card') {
      switch (gridKey) {
        case 'advancedSearch':
          yield put(
            longCardPaginationActions.changeLongCardPaginationSuccess({
              gridKey: gridKey,
              data: pagination,
            })
          );
          break;

        case 'lists':
          yield put(
            longCardPaginationActions.changeLongCardPaginationSuccess({
              gridKey: gridKey,
              data: pagination,
            })
          );
          break;

        case 'outreach':
          yield put(
            longCardPaginationActions.changeLongCardPaginationSuccess({
              gridKey: gridKey,
              data: pagination,
            })
          );
          break;

        case 'myCompanies':
          yield put(
            longCardPaginationActions.changeLongCardPaginationSuccess({
              gridKey: gridKey,
              data: pagination,
            })
          );
          break;

        case 'addons':
          yield put(
            longCardPaginationActions.changeLongCardPaginationSuccess({ gridKey, data: pagination })
          );
          break;

        default:
          break;
      }
    } else {
      const paginationGridKey = isSourceScrub ? 'sslists' : 'watchlists';
      yield put(
        paginationActions.changePaginationSuccess({
          gridKey: gridKey === 'lists' ? paginationGridKey : gridKey,
          data: searchData.pagination,
        })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

// clear
function* clearSearchSaga(
  action: PayloadAction<
    GridPayloadMain<Dictionary<PreselectedFilter> | boolean>,
    any,
    SuccessErrorCallback
  >
) {
  const { gridKey, data } = action.payload;
  const callback = action.meta;

  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey);
  let {
    filter: filterQuery,
    originalFilter: filter,
    subFilter,
    pagination,
    view,
    clearedFilters,
  } = gridSearchData;

  // data can either be boolean when coming from my companies page in order to reset the subfilter
  // or a Dictionary of PreselectedFilter. Only in the second case can filter be updated with the passed data.
  if (data && typeof data !== 'boolean') {
    filter = data;
  } else {
    filter = clearedFilters;
  }

  if (gridKey === 'outreach') {
    filter = yield select(outreachSearchSelectors.getClearedFilter);
  } else if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);

    // Ensures to filter by the selected list
    filter = {
      ...filter,
      ...(isSourceScrub ? { ss_list_id: listId } : { saved_list_id: listId }),
    };
  } else if (gridKey === 'myCompanies') {
    const authorizedComponents: Dictionary<boolean> = yield select(
      userInformationSelectors.authorizedComponents
    );
    let customClearedFilter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getCustomClearedFilter('myCompanies')
    );
    let companyOwner: Array<{ value: string; label: string }> | undefined;

    // for analysts we need to apply company owner filter
    // check is user is analyst, search for company owner filter,
    // get the company owner id for the current user
    if (!authorizedComponents.admin_portal_link) {
      const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
      const userName: string = yield select(userInformationSelectors.getFullName);

      companyOwner = getCompanyOwner(filterSources, userName);
      customClearedFilter = { ...customClearedFilter, company_owner_id: companyOwner };
    }

    filter = customClearedFilter;
  }

  filterQuery = yield call(getFiltersQuery, filter);

  pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? pagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const searchData: SearchPayloadRequest = {
    searchKey: '',
    filter: filterQuery,
    pagination,
    sortBy: COMPANY_DEFAULT_SORT,
  };

  if (gridKey === 'outreach') {
    const tableGridQuery: string = yield select(selectors.getTableGridQuery('outreach'));
    searchData.tableGridQuery = tableGridQuery;
    const defaultColumnName: SelectOption<string> | undefined = yield select(
      outreachSearchSelectors.getDefaultColumnName
    );
    const defaultAnalystId: SelectOption<string> | undefined = yield select(
      outreachSearchSelectors.getDefaultAnalystId
    );
    const defaultDateRange: [string | null, string | null] = yield select(
      outreachSearchSelectors.getDefaultDateRange
    );

    yield put(
      outreachFilterActions.updateSourcingFilterOptions({
        filterKey: 'ddate',
        value: defaultDateRange,
      })
    );
    yield put(
      outreachFilterActions.updateSourcingFilterOptions({
        filterKey: 'analyst_id',
        value: defaultAnalystId,
      })
    );
    yield put(
      outreachFilterActions.updateSourcingFilterOptions({
        filterKey: 'column_name',
        value: defaultColumnName,
      })
    );

    // clear all the filters that were applied in the filters panel
    yield put(outreachFilterActions.updateSourcingOutReachFilters({ panelFiltersQuery: '' }));

    // update panel filters in the history state so that after reload,
    // if user clears the filters, they should stay cleared
    if ((window.history.state?.state as TableGridQuery)?.panel_filters) {
      updateHistoryState('panel_filters', '');
    }
  }

  try {
    if (gridKey === 'myCompanies') {
      const selectedCardId: string = yield select(
        myCompaniesFilterCardsSelectors.getSelectedCardId
      );

      const res: AxiosResponse<Dictionary<SearchStateData>> = yield call(
        UserService.fetchMyCompaniesCards,
        '',
        filterQuery,
        selectedCardId ? (selectedCardId as string) : 'card1'
      );

      if (res.data) {
        const defaultOptxScore: string = yield select(userInformationSelectors.getDefaultScore);
        const defaultSortBy =
          defaultOptxScore === 'us' ? MYCOMPANIES_DEFAULT_SORT_US : MYCOMPANIES_DEFAULT_SORT_IL;
        const selectedCard: SearchStateData = yield select(
          myCompaniesFilterCardsSelectors.getSelectedCard
        );
        const cardsList = processCardList(res.data);
        yield put(myCompaniesFilterCardsActions.fetchMyCompaniesCardsSuccess(cardsList));
        searchData.filter =
          !data && subFilter
            ? {
                ...filterQuery,
                // @ts-ignore
                ...selectedCard.data[subFilter],
                ...cardsList[selectedCardId].filter,
              }
            : {
                ...filterQuery,
                ...cardsList[selectedCardId].filter,
              };
        searchData.sortBy = defaultSortBy;

        const userSettings: { [key: string]: number } = yield call(
          userInfoUpdateSaga,
          gridKey,
          pagination,
          true,
          true
        );
        yield call(
          searchCompanies,
          searchData,
          gridKey,
          filter,
          false,
          undefined,
          userSettings,
          callback
        );

        const allFiltersWithHistograms: string[] = yield select(
          myCompaniesSearchSelectors.selectAllFiltersWithHistograms
        );

        yield put(histogramActions.fetchHistogramFilters(filter, allFiltersWithHistograms));

        if (data) {
          yield put(
            actions.clearFilterSuccess({
              ...selectedCard,
              filter: cardsList[selectedCardId].filter,
            })
          );
        }

        yield put(myCompaniesSortActions.resetMultiSortAction());

        // also make call to count
        yield put(searchCountActions.searchCount({ gridKey, data: searchData }));

        if (view === 'long-card') {
          yield put(
            longCardPaginationActions.changeLongCardPaginationSuccess({
              gridKey: gridKey,
              data: pagination,
            })
          );
        } else {
          yield put(paginationActions.changePaginationSuccess({ gridKey, data: pagination }));
        }
      } else {
        const errorMessage = 'Error fetching My Companies!';
        yield put(myCompaniesFilterCardsActions.fetchMyCompaniesCardsFail(errorMessage));
        NotificationService.error(errorMessage);
      }
    } else {
      const userSettings: { [key: string]: number } = yield call(
        userInfoUpdateSaga,
        gridKey,
        pagination,
        true,
        true
      );
      yield call(
        searchCompanies,
        searchData,
        gridKey,
        filter,
        false,
        undefined,
        userSettings,
        callback
      );

      if (gridKey === 'lists') {
        yield put(histogramListsActions.fetchHistogramFilters(filter));
      } else if (gridKey !== 'outreach') {
        yield put(histogramActions.fetchHistogramFilters(filter));
      }

      // also make call to count
      if (gridKey === 'lists') {
        yield put(
          searchCountActions.searchCount({
            gridKey: isSourceScrub ? 'sslists' : 'watchlists',
            data: searchData,
          })
        );
      } else {
        yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
      }

      if (view === 'long-card') {
        switch (gridKey) {
          case 'advancedSearch':
            yield put(
              longCardPaginationActions.changeLongCardPaginationSuccess({
                gridKey: gridKey,
                data: pagination,
              })
            );
            break;

          case 'lists':
            yield put(
              longCardPaginationActions.changeLongCardPaginationSuccess({
                gridKey: gridKey,
                data: pagination,
              })
            );
            break;

          case 'outreach':
            yield put(
              longCardPaginationActions.changeLongCardPagination({
                gridKey: 'outreach',
                data: pagination,
              })
            );
            break;

          default:
            break;
        }
      } else {
        const paginationGridKey: GridKeys = isSourceScrub ? 'sslists' : 'watchlists';

        yield put(
          paginationActions.changePaginationSuccess({
            gridKey: gridKey === 'lists' ? paginationGridKey : gridKey,
            data: pagination,
          })
        );
      }
    }

    removeAllParametersFromURL();
  } catch (e: any) {
    if (gridKey === 'myCompanies') {
      const errorMessage = `Error fetching My Companies - ${e}`;
      yield put(myCompaniesFilterCardsActions.fetchMyCompaniesCardsFail(errorMessage));
      NotificationService.error(errorMessage);
    } else {
      yield call(searchCompaniesFail, gridKey, e);
    }
  }
}

export function* resetSearchToDefaultSaga(
  action: PayloadAction<GridPayloadMain<boolean>, any, SuccessErrorCallback>
) {
  const { gridKey, data } = action.payload;
  const callback = action.meta;

  const filterSourcesDefaultFilters: Dictionary<PreselectedFilter> = yield select(
    filterSourcesSelectors.getDefaultFilters
  );
  const defaultSearchkey: string = yield select(filterSourcesSelectors.getDefaultSearchkey);
  const defaultSorting: SortByRule<any>[] = yield select(filterSourcesSelectors.getDefaultSorting);

  const cancelSearch: boolean = yield select(selectors.isCancelled(gridKey));

  const view: SearchViewType = yield select(viewTypeSelectors.searchView(gridKey));
  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(paginationGridKey)
  );

  let pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);
  let { sortBy, originalFilter: filter, defaultFilters } = gridSearchData;

  if (Object.keys(filterSourcesDefaultFilters).length) filter = filterSourcesDefaultFilters;
  else filter = defaultFilters;

  if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);

    // Ensures to filter by the selected list
    filter = {
      ...filter,
      ...(isSourceScrub ? { ss_list_id: listId } : { saved_list_id: listId }),
    };
  }

  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? pagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const searchData: SearchPayloadRequest = {
    searchKey: defaultSearchkey,
    filter: filterQuery,
    pagination,
    sortBy: data ? defaultSorting || COMPANY_DEFAULT_SORT : sortBy,
  };

  try {
    if (gridKey === 'advancedSearch') {
      const userSettings: { [key: string]: number } = yield call(
        userInfoUpdateSaga,
        gridKey,
        pagination,
        true,
        true
      );
      yield call(
        searchCompanies,
        searchData,
        gridKey,
        filter,
        false,
        undefined,
        userSettings,
        callback
      );

      yield put(
        actions.resetSearchSuccess({
          gridKey,
          data: searchData.sortBy.length > 1,
        })
      );
    }

    if (gridKey === 'lists') {
      yield call(searchCompanies, searchData, gridKey, filter, false);
      yield put(actions.resetSearchSuccess({ gridKey, data: !data }));
      yield call(userInfoUpdateSaga, gridKey, pagination, false, true);
    }

    // also make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
    }

    if (view === 'long-card') {
      if (gridKey === 'lists') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      } else if (gridKey === 'advancedSearch') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      }
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: paginationGridKey, data: pagination })
      );
    }

    if (cancelSearch) {
      yield cancel();
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(actions.cancelCompanySearch({ gridKey, data: false }));
    }
  }
}

// load saved search
export function* loadCompanySavedSearchSaga(
  action: PayloadAction<
    GridPayloadMain<{
      searchId: number | string;
      title: string;
      listType: string;
    }>
  >
) {
  const {
    gridKey,
    data: { searchId, title, listType },
  } = action.payload;
  const savedSearch: SearchSave = yield select(state =>
    getSearchSavedSearchSelectors(state, searchId)
  );
  let {
    searchKey,
    filters,
    sortBy,
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
  } = savedSearch;

  let view: SearchViewType = yield select(viewTypeSelectors.searchView(gridKey));

  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(paginationGridKey)
  );
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);

    filters = {
      ...filters,
      ...(isSourceScrub
        ? { ss_list_id: listId, saved_list_id: undefined }
        : { saved_list_id: listId, ss_list_id: undefined }),
    };
  }

  const filterQuery: Dictionary<string | Array<number | string>> = yield call(
    getFiltersQuery,
    filters
  );

  const searchData: SearchPayloadRequest = {
    searchKey: searchKey ? preProcessSearch(searchKey) : '',
    filter: filterQuery,
    pagination,
    sortBy: sortBy || COMPANY_DEFAULT_SORT,
    fromSavedList: searchId,
    listType,
  };

  if ((gridKey === 'advancedSearch' && !isCustomUIView(searchId)) || gridKey === 'lists') {
    searchData.fromSavedList = searchId;
  }

  try {
    yield call(searchCompanies, searchData, gridKey, filters, false, title, false);

    // Update session settings filters and hide show quick filters by default.
    let sessionSettings: Partial<UpdateUserSettingsPayload> = {};

    if (sortBy) {
      const sortQuery = queryString.stringify(mapSortQuery(sortBy), {
        arrayFormat: 'comma',
      });
      sessionSettings.company_sorting = sortQuery;
    }

    if (columns !== null) {
      sessionSettings.company_display_columns = columns;

      if (columnOrder) {
        sessionSettings.company_column_order = columnOrder;
      }
    }

    if (pinnedColumns !== null) {
      sessionSettings.company_pinned_columns = pinnedColumns;
    }

    const userSettings: { [key: string]: number } = yield call(
      userInfoUpdateSaga,
      gridKey,
      pagination,
      true
    );

    sessionSettings = { ...sessionSettings, ...userSettings };

    if (gridKey === 'advancedSearch') {
      yield put(advancedSearchUpdateColumns(columns, columnOrder, pinnedColumns));
    } else if (gridKey === 'lists') {
      yield put(columnActions.updateColumns(columns, columnOrder, pinnedColumns));
    }

    yield fork(updateFilterSettings, searchData, gridKey, sessionSettings);

    if (gridKey === 'lists') {
      yield put(histogramListsActions.fetchHistogramFilters(filters));
    } else {
      yield put(histogramActions.fetchHistogramFilters(filters));
    }

    if (view === 'long-card') {
      if (gridKey === 'lists') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      } else if (gridKey === 'advancedSearch') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      }
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: paginationGridKey, data: pagination })
      );
    }

    // also make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

// load suggested search
function* loadSuggestedSearchSaga(action: PayloadAction<GridPayloadMain<LoadSuggestedSearch>>) {
  const {
    gridKey,
    data: { title },
  } = action.payload;

  // check if filters are loaded, if not get them first and then load the suggested search,
  // otherwise just make the search
  const filtersLoaded: boolean = yield select(filterSourcesSelectors.filtersLoaded);
  const shouldFetch: boolean = yield select(suggestedSearchSelectors.shouldFetch);
  const isAnalyst: boolean = yield select(userInformationSelectors.getIsAnalyst);

  if (!filtersLoaded && shouldFetch) {
    const settings: UserSettings = yield select(userInformationSelectors.getSettings);

    try {
      // @ts-ignore
      const filterRes = yield call(FilterService.getOptions);

      if (filterRes.data) {
        yield put(
          filterActions.fetchCompanyFiltersSuccess(filterRes.data.filters, {
            defaultViews: filterRes.data.default_views,
            searchOrigin: filterRes.data.search_origin,
            settings,
            isAnalyst,
          })
        );

        yield call(fetchSuggestedSearches, filterRes.data.filters, gridKey, title);
      } else {
        yield put(filterActions.fetchCompanyFiltersFail('Error fetch company filters!'));
        yield put(actions.loadCompaniesByFilterCompleted());
      }
    } catch (e: any) {
      yield put(filterActions.fetchCompanyFiltersFail('Error fetch company filters!'));
      yield put(actions.loadCompaniesByFilterCompleted());
    }
  } else if (shouldFetch) {
    const filterSources: FilterSource[] = yield select(filterSourcesSelectors.getCompanyFilters);
    yield call(fetchSuggestedSearches, filterSources, gridKey, title);
  } else {
    yield put(push(isAnalyst ? routes.advancedSearch : routes.home));
    yield call(loadSuggestedSearch, title, gridKey);
  }
}

/**
 * load the suggested search
 * @param {number} id - list unique id
 * @param {GridKeysMain} gridKey - grid key
 */
function* loadSuggestedSearch(title: string, gridKey: GridKeysMain) {
  const suggestedSearch: SearchSave = yield select(
    suggestedSearchSelectors.getSuggestedSearch(title)
  );
  const {
    searchKey,
    filters,
    sortBy,
    columns,
    column_order: columnOrder,
    pinned_columns: pinnedColumns,
  } = suggestedSearch;

  const filterQuery: Dictionary<string | Array<number | string>> = yield call(
    getFiltersQuery,
    filters
  );
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('advancedSearch'));
  const userPageNumber: number = yield select(userInformationSelectors.getResultsPageNumber);
  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(paginationGridKey)
  );
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const searchData: SearchPayloadRequest = {
    searchKey: searchKey ? preProcessSearch(searchKey) : '',
    filter: filterQuery,
    searchTitle: title,
    pagination,
    sortBy: sortBy || COMPANY_DEFAULT_SORT,
  };

  const sortQuery = queryString.stringify(mapSortQuery(sortBy || COMPANY_DEFAULT_SORT), {
    arrayFormat: 'comma',
  });

  try {
    yield call(searchCompanies, searchData, gridKey, filters, false, title, {
      ...(columnOrder && { company_column_order: columnOrder }),
      ...(columns && { company_display_columns: columns }),
      ...(pinnedColumns && { company_pinned_columns: pinnedColumns }),
    });
    yield put(advancedSearchUpdateColumns(columns, columnOrder, pinnedColumns));

    yield put(actions.clearSearchSuccess({ gridKey, data: false }));
    // Update session settings filters and hide show quick filters by default.
    const showQuickFilters: boolean = yield select(userInformationSelectors.showQuickFilters);

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield fork(updateFilterSettings, searchData, gridKey, {
        company_sorting: sortQuery,
        search_results_page_number: searchData.pagination.pageNumber,
        ...(showQuickFilters && { show_quick_filters: false }),
      });
    } else {
      yield fork(updateFilterSettings, searchData, gridKey, {
        company_sorting: sortQuery,
        ...(showQuickFilters && { show_quick_filters: false }),
      });
    }

    if (gridKey === 'lists') {
      yield put(histogramListsActions.fetchHistogramFilters(filters));
    } else {
      yield put(histogramActions.fetchHistogramFilters(filters));
    }

    if (view === 'long-card') {
      if (gridKey === 'lists' || gridKey === 'advancedSearch') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      }
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: paginationGridKey, data: pagination })
      );
    }

    // also make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(
        searchCountActions.searchCount({ gridKey, data: { ...searchData, searchTitle: title } })
      );
    }

    yield put(actions.loadCompaniesByFilterCompleted());
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

/**
 * the suggested searches will be called once only when clicking on Browse by Recently Funded
 * menu item and only after getting the filters from api
 * @param { FilterSource<Filter<any>>[]} filterSources
 */
function* fetchSuggestedSearches(
  filterSources: FilterSource<Filter<any>>[],
  gridKey: GridKeysMain,
  title: string
) {
  const isAnalyst: boolean = yield select(userInformationSelectors.getIsAnalyst);

  try {
    // @ts-ignore
    const searchRes = yield call(SavedSearchesService.getSuggestedSearches);

    if (searchRes.data) {
      yield put(
        suggestedSearchActions.fetchSuggestedSearchesSuccess(
          searchRes.data.lists,
          filterSources,
          mapPreselectedFilters(filterSources, false)
        )
      );
      yield put(push(isAnalyst ? routes.advancedSearch : routes.home));
      yield call(loadSuggestedSearch, title, gridKey);
    } else {
      const errorMessage = 'Error fetching suggested searches!';
      yield put(suggestedSearchActions.fetchSuggestedSearchesFail(errorMessage));
      NotificationService.error(errorMessage);
    }
  } catch (e: any) {
    const errorMessage = 'Error fetching suggested searches!';
    yield put(suggestedSearchActions.fetchSuggestedSearchesFail(errorMessage));
    NotificationService.error(errorMessage);
  }
}

// Reset filter when tag is removed.
export function* resetFilterSaga(action: PayloadAction<GridPayloadMain<ResetFilter>>) {
  const { gridKey, data } = action.payload;
  const { filterTags: allTags, selectedTag: filterTag } = data;

  const view: SearchViewType = yield select(viewTypeSelectors.searchView(gridKey));
  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  const isSimilarCompaniesSorting: boolean | undefined = yield select(
    advancedSearchSearchSelectors.isSimilarCompaniesSorting
  );
  const similarCompanyInfo: SelectOption<string> = yield select(
    filterSelectors.getSimilarCompanyName()
  );

  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(paginationGridKey)
  );
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);
  let { sortBy, searchKey, originalFilter, clearedFilters } = gridSearchData;

  const normalizedFilterSources: Dictionary<BaseFilter<any>> = yield select(
    filterSourcesSelectors.getFiltersMap
  );

  let filters = injectFilter(
    normalizedFilterSources,
    originalFilter,
    clearedFilters,
    filterTag.filter,
    filterTag.optionId
  );

  const clearedFields = removeTouchFilters(filters, filterTag);

  if (filterTag.resetFor?.length) {
    filterTag.resetFor.forEach((filterKey: string) => {
      const selectedToClear = allTags.find(tag => tag.filter === filterKey);

      if (selectedToClear) {
        filters = injectFilter(
          normalizedFilterSources,
          filters,
          clearedFilters,
          selectedToClear.filter,
          selectedToClear.optionId
        );
      }
    });
  }

  filters = { ...filters, ...clearedFields };

  if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);

    // Ensures to filter by the selected list
    filters = {
      ...filters,
      ...(isSourceScrub ? { ss_list_id: listId } : { saved_list_id: listId }),
    };
  }

  const filterQuery: Dictionary<string | Array<number | string>> = yield call(
    getFiltersQuery,
    filters
  );

  const searchData: SearchPayloadRequest = {
    searchKey,
    filter: filterQuery,
    pagination,
    sortBy,
  };

  try {
    const searchOriginKey = getSearchOriginKeyFromGridKey(gridKey);

    const filtersSearchOriginValue: number = yield select(state =>
      filterSourcesSelectors.getFilterSearchOrigin(state, searchOriginKey)
    );

    let payloadData = { ...searchData };

    if (typeof filtersSearchOriginValue === 'number') {
      payloadData = {
        ...payloadData,
        searchOrigin: filtersSearchOriginValue,
      };
    }

    let searchRes: AxiosResponse<Company[]>;

    if (gridKey === 'addons') {
      const companyId: number = yield select(profileSelectors.getCompanyId);
      // we don't need search origin and date range filters on addon page
      delete payloadData.searchOrigin;
      delete payloadData.filter.ddate_lower;
      delete payloadData.filter.ddate_upper;
      payloadData.companyId = companyId;

      searchRes = yield call(
        CompanyService.fetchCompanyAddonManagement,
        payloadData as AddonManagementPayload
      );
    } else {
      searchRes = yield call(SearchService.searchCompanies, payloadData);
    }

    if (gridKey === 'advancedSearch') {
      yield put(histogramActions.fetchHistogramFilters(filters));
    } else if (gridKey === 'lists') {
      yield put(histogramListsActions.fetchHistogramFilters(filterQuery));
    }

    const cancelSearch: boolean = yield select(selectors.isCancelled(gridKey));

    if (cancelSearch) {
      yield cancel();
    }

    let columns: string = '';
    let columnOrder: ColumnKeys[] = [];
    let pinnedColumns: string = '';
    let companyId: number | undefined = undefined;

    switch (gridKey) {
      case 'advancedSearch':
        columns = yield select(advancedSearchColumnSelectors.getVisibleColumnString);
        columnOrder = yield select(advancedSearchColumnSelectors.getColumnOrder);
        pinnedColumns = yield select(advancedSearchColumnSelectors.getPinnedColumnsString);
        break;

      case 'addons':
        companyId = yield select(profileSelectors.getCompanyId);
        break;

      case 'lists':
        columns = yield select(listsSearchCommonSelectors.getVisibleColumnString);
        columnOrder = yield select(listsSearchCommonSelectors.getColumnOrder);
        pinnedColumns = yield select(listsSearchCommonSelectors.getPinnedColumnsString);

        break;

      default:
        break;
    }

    const savedSearches: SearchSave[] = yield select(getSavedSearchesSavedSearchSelectors);
    const { defaultOptxView } = yield select(getViewsSavedSearchSelectors);

    const { matchedTitle, matchedView } = matchView(
      searchData,
      columns,
      columnOrder.join(),
      pinnedColumns,
      savedSearches,
      defaultOptxView
    );

    yield put(
      applyViewSuccessSavedSearchesActions({
        id: matchedView && matchedView.unique_id ? matchedView.unique_id : '',
        label: matchedTitle || '',
        pageAlias: getRouteAliasFromGridKey(gridKey),
      })
    );

    if (gridKey === 'advancedSearch') {
      yield put(longCardActions.fetchCompanyProfileSuccess(searchRes.data[0] as CompanyProfile));
    }

    const companies: Company[] =
      gridKey === 'addons'
        ? (searchRes.data as unknown as { data: Company[] }).data
        : searchRes.data;

    yield put(
      actions.searchCompaniesSuccess(
        sortSimilarCompanies(isSimilarCompaniesSorting, companies, similarCompanyInfo),
        {
          gridKey,
          data: {
            ...searchData,
            filter: filters,
            searchTitle:
              matchedView &&
              matchedView.unique_id.toString() !== 'default' &&
              hasFilters(matchedView.search_criteria)
                ? matchedTitle
                : '',
            ...(gridKey === 'addons' && { companyId: yield select(profileSelectors.getCompanyId) }),
          },
        }
      )
    );

    if (view === 'long-card') {
      if (gridKey === 'lists' || gridKey === 'advancedSearch') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      }
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: paginationGridKey, data: pagination })
      );
    }

    const userSettings: { [key: string]: number } = yield call(
      userInfoUpdateSaga,
      gridKey,
      pagination,
      true
    );

    yield call(
      updateFilterSettings,
      searchData,
      gridKey,
      isEqual(userSettings, {}) ? undefined : userSettings
    );

    // also make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(searchCountActions.searchCount({ gridKey, data: searchData, companyId }));
    }

    // remove filter from URL
    removeParameterFromURL(filterTag.filter);
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(actions.cancelCompanySearch({ gridKey, data: false }));
    }
  }
}

export function* removeFilterSaga(action: PayloadAction<GridPayloadMain<ResetFilter>>) {
  const { gridKey, data } = action.payload;
  const { selectedTag: filterTag, filterTags: allTags } = data;

  const { filter: tagName, optionId: tagId } = filterTag;

  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(paginationGridKey)
  );

  const view: SearchViewType = yield select(viewTypeSelectors.searchView(gridKey));

  let pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey, pagination);
  let { sortBy, subFilter, searchKey, originalFilter, clearedFilters } = gridSearchData;

  // if we're removing the tag we just need to make a call to company cards endppoint,
  // if we're remving the card subfilter we just need to make a call to search endpoint,
  // if we're removing a regular filter, update filter list and make call to company cards
  // every call made to fetch my companies cards also makes a call to search companies
  if (tagName === 'keyword' && gridKey === 'myCompanies') {
    yield put(
      myCompaniesFilterCardsActions.fetchMyCompaniesCards({
        searchKey: '',
        filter: originalFilter,
        shouldResetPageNumber: true,
      })
    );
  } else if (tagName === subFilter) {
    const filterQuery: Dictionary<string | Array<number | string>> = yield call(
      getFiltersQuery,
      originalFilter
    );

    pagination = {
      pageNumber: 1,
      pageSize: view === 'table' ? pagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
    };

    const searchData: SearchPayloadRequest = {
      searchKey,
      filter: filterQuery,
      pagination,
      sortBy,
    };

    if (gridKey === 'myCompanies') {
      const selectedCard: SearchStateData = yield select(
        myCompaniesFilterCardsSelectors.getSelectedCard
      );

      searchData.filter = {
        ...selectedCard.filter,
        ...filterQuery,
        saved_list_id: selectedCard.filter.saved_list_id,
      };
    }

    try {
      const userSettings: { [key: string]: number } = yield call(
        userInfoUpdateSaga,
        gridKey,
        pagination,
        true,
        true
      );
      yield call(
        searchCompanies,
        searchData,
        gridKey,
        originalFilter,
        false,
        undefined,
        userSettings
      );

      yield put(actions.clearFilterSuccess());

      if (gridKey === 'lists') {
        yield put(
          searchCountActions.searchCount({
            gridKey: isSourceScrub ? 'sslists' : 'watchlists',
            data: searchData,
          })
        );
      } else {
        yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
      }

      if (view === 'long-card') {
        switch (gridKey) {
          case 'advancedSearch':
            yield put(
              longCardPaginationActions.changeLongCardPaginationSuccess({
                gridKey: gridKey,
                data: pagination,
              })
            );
            break;

          case 'lists':
            yield put(
              longCardPaginationActions.changeLongCardPaginationSuccess({
                gridKey: gridKey,
                data: pagination,
              })
            );
            break;

          case 'outreach':
            yield put(
              longCardPaginationActions.changeLongCardPagination({
                gridKey: 'outreach',
                data: pagination,
              })
            );
            break;

          case 'myCompanies':
            yield put(
              longCardPaginationActions.changeLongCardPaginationSuccess({
                gridKey: gridKey,
                data: pagination,
              })
            );
            break;

          default:
            break;
        }
      } else {
        yield put(
          paginationActions.changePaginationSuccess({
            gridKey: paginationGridKey,
            data: pagination,
          })
        );
      }
    } catch (error: any) {
      yield call(searchCompaniesFail, gridKey, error);
    }
  } else {
    const normalizedFilterSources: Dictionary<BaseFilter<any>> = yield select(
      filterSourcesSelectors.getFiltersMap
    );

    let filter = injectFilter(
      normalizedFilterSources,
      originalFilter,
      clearedFilters,
      tagName,
      tagId
    );

    const clearedFields = removeTouchFilters(filter, filterTag);

    if (filterTag.resetFor?.length) {
      filterTag.resetFor.forEach((filterKey: string) => {
        const selectedToClear = allTags.find(tag => tag.filter === filterKey);

        if (selectedToClear) {
          filter = injectFilter(
            normalizedFilterSources,
            filter,
            clearedFilters,
            selectedToClear.filter,
            selectedToClear.optionId
          );
        }
      });
    }

    filter = { ...filter, ...clearedFields };

    const data: Partial<SearchPayload> = {
      searchKey,
      filter,
      shouldResetPageNumber: true,
    };

    if (gridKey === 'outreach') {
      yield put(
        outreachFilterActions.updateSourcingOutReachFilters({
          panelFiltersQuery: tagName,
          shouldRemoveFilter: true,
        })
      );

      // update panel filters in the history state so that after reload,
      // if user removes the filter, it should stay removed
      const panelFilters = (window.history.state?.state as TableGridQuery)?.panel_filters;

      if (panelFilters) {
        const parsedPanelFilters = queryString.parse(panelFilters, { arrayFormat: 'comma' });

        delete parsedPanelFilters[tagName];

        updateHistoryState('panel_filters', queryString.stringify(parsedPanelFilters));
      }
    }

    if (gridKey === 'myCompanies') {
      yield put(myCompaniesFilterCardsActions.fetchMyCompaniesCards(data));
    } else {
      yield put(actions.searchCompanies({ gridKey, data }));
    }
  }
}

export function* loadAutocompleteSearch(
  action: PayloadAction<GridPayloadMain<SearchAutocomplete>>
) {
  const { gridKey, data: autocompleteInfo } = action.payload;

  let view: SearchViewType = yield select(viewTypeSelectors.searchView(gridKey));
  const gridSearchData: GetGridSearchData = yield call(getGridSearchDataSaga, gridKey);
  let { defaultFilters, pagination } = gridSearchData;

  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? pagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };

  if (gridKey === 'lists') {
    const listId: string = yield select(listsDetailsSelectors.getRouteListId);

    // Ensures to filter by the selected list
    defaultFilters = {
      ...defaultFilters,
      ...(isSourceScrub ? { ss_list_id: listId } : { saved_list_id: listId }),
    };
  }

  const filterQuery: Dictionary<string | Array<number | string>> = yield call(
    getFiltersQuery,
    defaultFilters
  );

  const searchData: SearchPayloadRequest = {
    searchKey: preProcessSearch(autocompleteInfo.search_info.keyword),
    filter: filterQuery,
    pagination,
    sortBy: [],
  };

  try {
    const userSettings: { [key: string]: number } = yield call(
      userInfoUpdateSaga,
      gridKey,
      pagination,
      true
    );
    yield call(
      searchCompanies,
      searchData,
      gridKey,
      defaultFilters,
      false,
      undefined,
      userSettings
    );

    const cancelSearch: boolean = yield select(selectors.isCancelled(gridKey));

    if (cancelSearch) {
      yield cancel();
    }

    // also make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
    }

    if (gridKey === 'lists') {
      yield put(histogramListsActions.fetchHistogramFilters(defaultFilters));
    } else {
      yield put(histogramActions.fetchHistogramFilters(defaultFilters));
    }

    if (view === 'long-card') {
      if (gridKey === 'lists' || gridKey === 'advancedSearch') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      }
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: paginationGridKey, data: pagination })
      );
    }
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      yield put(actions.cancelCompanySearch({ gridKey, data: false }));
    }
  }
}

/**
 * perform the search by loading filters outside filter modal
 * @param {string} filterValue
 * @param {string} filterKey
 * @param {Dictionary<PreselectedFilter>} clearedFilter
 * @param {GridKeysMain} gridKey
 */
function* loadFilter(
  filterValue: string,
  filterKey: string,
  clearedFilter: Dictionary<PreselectedFilter>,
  gridKey: GridKeysMain
) {
  const view: SearchViewType = yield select(viewTypeSelectors.searchView('advancedSearch'));
  const userPageNumber: number = yield select(userInformationSelectors.getResultsPageNumber);
  const isSourceScrub: boolean = yield select(listsDetailsSelectors.isSourceScrubRoute);
  let paginationGridKey: GridKeys = gridKey;

  if (gridKey === 'lists') {
    if (isSourceScrub) paginationGridKey = 'sslists';
    else paginationGridKey = 'watchlists';
  }

  const regularPagination: PageInfo = yield select(
    paginationSelectors.getPagination(paginationGridKey)
  );
  const pagination = {
    pageNumber: 1,
    pageSize: view === 'table' ? regularPagination.pageSize : LONG_CARD_VIEW_DEFAULT_PAGE_SIZE,
  };
  // include the special filter in filters.
  const filter: Dictionary<PreselectedFilter> = {
    ...clearedFilter,
    [filterKey]:
      filterKey === 'company_tag' ? [{ value: filterValue, label: filterValue }] : filterValue,
  };
  const filterQuery: Dictionary<string | (string | number)[]> = yield call(getFiltersQuery, filter);

  const searchData: SearchPayloadRequest = {
    searchKey: '',
    filter: filterQuery,
    pagination,
    sortBy: COMPANY_DEFAULT_SORT,
  };

  const sortQuery = queryString.stringify(mapSortQuery(COMPANY_DEFAULT_SORT), {
    arrayFormat: 'comma',
  });

  try {
    const searchOriginKey: FilterSearchOriginName = 'profile_page';
    const filtersSearchOriginValue: number = yield select(state =>
      filterSourcesSelectors.getFilterSearchOrigin(state, searchOriginKey)
    );

    let payloadData = { ...searchData };

    if (typeof filtersSearchOriginValue === 'number') {
      payloadData = {
        ...payloadData,
        searchOrigin: filtersSearchOriginValue,
      };
    }

    const res: AxiosResponse<Company[]> = yield call(
      SearchService.searchCompanies,
      payloadData,
      false
    );

    yield put(
      actions.searchCompaniesSuccess(res.data, {
        gridKey,
        data: { ...searchData, filter },
      })
    );

    if (searchData.pagination.pageNumber !== userPageNumber) {
      yield call(updateFilterSettings, searchData, gridKey, {
        company_sorting: sortQuery,
        search_results_page_number: searchData.pagination.pageNumber,
      });
    } else {
      yield call(updateFilterSettings, searchData, gridKey, { company_sorting: sortQuery });
    }

    yield put(listsDetailsActions.clearCompanyList());

    if (view === 'long-card') {
      if (gridKey === 'lists' || gridKey === 'advancedSearch') {
        yield put(
          longCardPaginationActions.changeLongCardPaginationSuccess({
            gridKey: gridKey,
            data: pagination,
          })
        );
      }
    } else {
      yield put(
        paginationActions.changePaginationSuccess({ gridKey: paginationGridKey, data: pagination })
      );
    }

    // make call to count
    if (gridKey === 'lists') {
      yield put(
        searchCountActions.searchCount({
          gridKey: isSourceScrub ? 'sslists' : 'watchlists',
          data: searchData,
        })
      );
    } else {
      yield put(searchCountActions.searchCount({ gridKey, data: searchData }));
    }

    yield put(actions.loadCompaniesByFilterCompleted());
  } catch (error: any) {
    yield call(searchCompaniesFail, gridKey, error);
  }
}

/**
 * Load companies by specific filter, from outside filter modal
 * @param action
 */
export function* loadCompaniesByFilterSaga(
  action: PayloadAction<GridPayloadMain<string>, any, string>
) {
  // check if filters are loaded, if not get them first and then perform the search with the special filter,
  // otherwise just make the search
  const filtersLoaded: boolean = yield select(filterSourcesSelectors.filtersLoaded);
  const isAnalyst: boolean = yield select(userInformationSelectors.getIsAnalyst);
  const {
    payload: { gridKey, data: filterValue },
    meta: filterKey,
  } = action;

  if (!filtersLoaded) {
    const settings: UserSettings = yield select(userInformationSelectors.getSettings);

    try {
      // @ts-ignore
      const res = yield call(FilterService.getOptions);

      if (res.data) {
        yield put(
          filterActions.fetchCompanyFiltersSuccess(res.data.filters, {
            defaultViews: res.data.default_views,
            searchOrigin: res.data.search_origin,
            settings,
            isAnalyst,
          })
        );

        yield call(
          loadFilter,
          filterValue,
          filterKey,
          mapPreselectedFilters(res.data.filters, false),
          gridKey
        );

        yield put(push(isAnalyst ? routes.advancedSearch : routes.home));
      } else {
        yield put(filterActions.fetchCompanyFiltersFail('Error fetch company filters!'));
        yield put(actions.loadCompaniesByFilterCompleted());
      }
    } catch (e: any) {
      yield put(filterActions.fetchCompanyFiltersFail('Error fetch company filters!'));
      yield put(actions.loadCompaniesByFilterCompleted());
    }
  } else {
    const clearedFilter: Dictionary<PreselectedFilter> = yield select(
      filterSelectors.getClearedFilter('advancedSearch')
    );
    yield put(push(isAnalyst ? routes.advancedSearch : routes.home));
    yield call(loadFilter, filterValue, filterKey, clearedFilter, gridKey);
  }
}

type CompanyKey = keyof Company;
const diversityKey: CompanyKey = 'diversity';

function* loadDiversityFilterSaga(action: PayloadAction<string>) {
  const value = action.payload;
  const options: Array<SelectOption> = yield select(state =>
    filterSourcesSelectors.getSelectOptions(state, diversityKey)
  );
  const option = options.find(option => option.value === value);

  if (option) {
    let gridKey = getLocationMainGridKey();
    if (!gridKey) return;

    yield put(
      actions.loadCompaniesByFilter(diversityKey, {
        gridKey,
        data: [option],
      })
    );
  }
}

function* saveTrendingInSessionSaga(action: PayloadAction<GridPayloadMain<ChartBarIntervalKeys>>) {
  const { gridKey } = action.payload;

  const trending: ChartBarIntervalKeys = yield select(selectors.getTrending(gridKey));

  const updateUserSettingsData: { [key: string]: ChartBarIntervalKeys } = {};

  switch (gridKey) {
    case 'advancedSearch':
      updateUserSettingsData.search_trending = trending;
      break;

    case 'lists':
      updateUserSettingsData.user_list_trending = trending;
      break;

    case 'outreach':
      updateUserSettingsData.sourcing_outreach_trendings = trending;
      break;

    case 'myCompanies':
      updateUserSettingsData.my_companies_trending = trending;
      break;

    default:
      break;
  }

  yield put(userInformationActions.updateUserSettings(updateUserSettingsData));
}

/**
 * Each time the user makes a new search reset scroll history if needed.
 */
function* searchCompaniesSuccessSaga(
  action: PayloadAction<Company[], any, GridPayloadMain<Partial<SearchPayload>>>
) {
  const { gridKey } = action.meta;
  const isAnalyst: boolean = yield select(userInformationSelectors.getIsAnalyst);
  let route = isAnalyst ? routes.advancedSearch : routes.home;

  switch (gridKey) {
    case 'myCompanies':
      route = routes.myCompanies;
      break;

    case 'lists':
      route = routes.userLists.list;
      break;
  }

  yield put(scrollHistoryActions.resetScrollHistory(route));
  yield put(incrementFetchedCount(gridKey));
}

export default function* searchSaga() {
  yield takeLatest(actions.initialCompaniesSearch, initializeSearchSaga);
  yield takeLatest(actions.searchCompanies, searchWithCountSaga);
  yield takeLatest(actions.clearSearch, clearSearchSaga);
  yield takeLatest(actions.resetSearch, resetSearchToDefaultSaga);
  yield takeLatest(actions.loadSavedSearch, loadCompanySavedSearchSaga);
  yield takeLatest(actions.loadSuggestedSearch, loadSuggestedSearchSaga);
  yield takeLatest(actions.loadAutocompleteSearch, loadAutocompleteSearch);
  yield takeLatest(actions.resetFilter, resetFilterSaga);
  yield takeLatest(actions.removeFilter, removeFilterSaga);
  yield takeLatest(actions.loadCompaniesByFilter, loadCompaniesByFilterSaga);
  yield takeLatest(actions.loadDiversityFilter, loadDiversityFilterSaga);
  yield takeLatest(actions.updateTrending, saveTrendingInSessionSaga);
  yield takeLatest(actions.searchCompaniesSuccess, searchCompaniesSuccessSaga);
}
