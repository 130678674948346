import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { isEqual } from 'lodash';
// models
import { SelectOption } from '@optx/models/Option';
import { FinancialFieldRangeValue } from '@optx/models/Company';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { validateField } from './editFields';

export const financialYearValidationSchema = ({
  ebitdaOptions,
  revenueOptions,
  arrOptions,
  gmOptions,
}: {
  ebitdaOptions: SelectOption[];
  revenueOptions: SelectOption[];
  arrOptions: SelectOption[];
  gmOptions: SelectOption[];
}) =>
  Yup.object({
    year: Yup.number().required('Year is required'),
    estimated: Yup.boolean().required('Estimated is required'),
    arr: Yup.string().test('isValid', 'Valid', value => {
      if (value === undefined) {
        return true;
      }

      return validateField(value, 'ARR', arrOptions, false);
    }),
    ebtida: Yup.string().test('isValid', 'Valid', value => {
      if (value === undefined) {
        return true;
      }

      return validateField(value, 'EBTIDA', ebitdaOptions, false);
    }),
    revenue: Yup.string().test('isValid', 'Valid', value => {
      if (value === undefined) {
        return true;
      }

      return validateField(value, 'Revenue', revenueOptions, false);
    }),
    gm: Yup.mixed().test('isValid', 'Valid', value => {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'undefined') {
        if (value === undefined || value === DEFAULT_EMPTY_VALUE) {
          return true;
        }

        return validateField(value, 'GM %', gmOptions, false);
      }

      return false;
    }),
  });

export const checkFinancialValues = (
  value: string | number | FinancialFieldRangeValue,
  financialType: string,
  listOfYears: SelectOption<string>[],
  isEditable: boolean,
  options: SelectOption[]
) => {
  if (financialType === 'year' && !isEditable) {
    const mostRecentYearAvailable = listOfYears.find(list => list.disabled === false);

    return isNaN(Number(mostRecentYearAvailable?.value))
      ? ''
      : Number(mostRecentYearAvailable?.value);
  }

  if (typeof value === 'object') {
    const option = options.find(option => isEqual(option.rangeValue, value));

    return option?.label;
  }

  if (typeof value === 'string' || financialType === 'gm' || financialType === 'year') {
    if (value === DEFAULT_EMPTY_VALUE || value === '') {
      return '';
    }

    return value;
  }

  return isNaN(value / 1000000) ? '' : value / 1000000;
};

/**
 * Checks if the selected value is equal to the current value.
 * @param {(string|number|string[]|undefined|null|Moment)} selectedValue - The value selected by the user.
 * @param {(string|undefined|number|null|string[]|Moment)} currentValue - The current value.
 * @returns {boolean} - Returns true if the selected value is equal to the current value, false otherwise.
 */
export const checkSaveButton = (
  selectedValue: string | number | string[] | undefined | null | Moment,
  currentValue: string | undefined | number | null | string[] | Moment
) => {
  if (
    isEqual(selectedValue, currentValue) ||
    selectedValue === undefined ||
    (currentValue === null && selectedValue === '') ||
    (currentValue === 0 && Number.isNaN(selectedValue)) ||
    (currentValue === 0 && selectedValue === null) ||
    (currentValue === undefined && selectedValue === '') ||
    (moment.isMoment(currentValue) && !moment(selectedValue).isValid())
  ) {
    return true;
  }

  return false;
};
