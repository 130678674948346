import React from 'react';
import { Dictionary } from 'lodash';
import { useFormikContext } from 'formik';
import { Form, Input, InputNumber, Select, Checkbox } from 'formik-antd';
// utils
import { filterOption } from '@utils/select/select';
// models
import {
  EquityTouchDatabaseMatch,
  EquityTouchKeys,
  EquityTouchFetchedField,
} from '@models/equityTouch';
// components
import { SourceDescriptionFieldItem } from '@components/pages/EquityTouch/components/SourceDescriptionFieldItem';
import { EquityTouchGroupsField } from '@components/pages/EquityTouch/components/EquityTouchGroupsField';
import BulkEquityTouchGroupsField from '@features/bulk-actions/equity-touch/components/BulkEquityTouchGroupsField';
import BulkEquityTouchAddon from '@features/bulk-actions/equity-touch/components/BulkEquityTouchAddon';
import BulkEquityTouchLead from '@features/bulk-actions/equity-touch/components/BulkEquityTouchLead';
import BulkEquityTouchAddonCheckbox from '@features/bulk-actions/equity-touch/components/BulkEquityTouchAddonCheckbox';
import EquityTouchAddon from './EquityTouchAddon';
import EquityTouchProductCategory from './EquityTouchProductCategory';

interface FieldItemsProps {
  field: EquityTouchKeys;
  initialFormData: Dictionary<EquityTouchFetchedField>;
  readOnly?: boolean;
}
const { Option } = Select;

export const FieldItems: React.FC<FieldItemsProps> = ({ field, initialFormData, readOnly }) => {
  const isBulk = window.location.href.includes('bulk');

  const fieldItem = initialFormData[field];
  const customCssClass = `equity-touch-field__${field.split(' ').join('-').toLowerCase()}`;

  const {
    // @ts-ignore
    values: { 'Deal Team Lead': dealTeamLead },
  } = useFormikContext();

  if (!field || !fieldItem) {
    return null;
  }

  if (field === 'Groups') {
    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label={fieldItem.label}
        key={field}
        required={fieldItem.is_required}
      >
        {isBulk ? (
          <BulkEquityTouchGroupsField readOnly={readOnly} />
        ) : (
          <EquityTouchGroupsField dealTeamLead={dealTeamLead} initialFormData={initialFormData} />
        )}
      </Form.Item>
    );
  }

  if (fieldItem.type === 'select' && (field === 'Country' || field === 'State')) {
    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label={fieldItem.label}
        key={field}
        required={fieldItem.is_required}
      >
        <Select
          name={field}
          showSearch
          filterOption={filterOption}
          placeholder={`Please select ${field}`}
          defaultValue={fieldItem?.database_matched?.itemId}
          disabled={readOnly}
        >
          {!fieldItem.is_required && <Option value="">{`Please select ${field}`}</Option>}
          {fieldItem.received_values.map((value, index) => (
            <Option value={(value as EquityTouchDatabaseMatch).itemId} key={index}>
              {(value as EquityTouchDatabaseMatch).text}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  if (fieldItem.type === 'select' && field === 'Stage') {
    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label={fieldItem.label}
        key={field}
        required={fieldItem.is_required}
      >
        <Select
          name={field}
          placeholder="None"
          disabled={readOnly}
          filterOption={filterOption}
          showSearch
        >
          {!fieldItem.is_required && <Option value="">None</Option>}

          {fieldItem.received_values.map((value, index) => (
            <Option value={(value as EquityTouchDatabaseMatch).itemId} key={index}>
              {(value as EquityTouchDatabaseMatch).text}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  if (fieldItem.type === 'select' && field === 'Deal Team Lead') {
    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label={fieldItem.label}
        key={field}
        required={fieldItem.is_required}
      >
        {isBulk ? (
          <BulkEquityTouchLead field="Deal Team Lead" fieldItem={fieldItem} readOnly={readOnly} />
        ) : (
          <Select
            name={field}
            placeholder={`Please select ${field}`}
            filterOption={filterOption}
            showSearch
          >
            {!fieldItem.is_required && <Option value="">{`Please select ${field}`}</Option>}

            {fieldItem.received_values.map((value, index) => (
              <Option value={(value as EquityTouchDatabaseMatch).itemId} key={index}>
                {(value as EquityTouchDatabaseMatch).text}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }

  if (fieldItem.type === 'select' && field !== 'Country' && field !== 'State') {
    return (
      <Form.Item name={field} label={fieldItem.label} key={field} required={fieldItem.is_required}>
        <Select
          name={field}
          placeholder={`Please select ${field}`}
          disabled={readOnly}
          filterOption={filterOption}
          defaultValue={fieldItem?.database_matched?.itemId}
          showSearch
        >
          {!fieldItem.is_required && <Option value="">{`Please select ${field}`}</Option>}

          {fieldItem.received_values.map((value, index) => (
            <Option value={(value as EquityTouchDatabaseMatch).itemId} key={index}>
              {(value as EquityTouchDatabaseMatch).text}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  if (fieldItem.type === 'select_suggest' && field !== 'Product Category') {
    return (
      <Form.Item
        name={field}
        label={fieldItem.label}
        key={field}
        className={customCssClass}
        required={fieldItem.is_required}
      >
        {isBulk ? (
          <BulkEquityTouchAddon name={field} readOnly={readOnly} />
        ) : (
          <EquityTouchAddon name={field} initialValue={initialFormData[field]} />
        )}
      </Form.Item>
    );
  }

  if (field === 'Description' && fieldItem.type === 'textarea') {
    return (
      <SourceDescriptionFieldItem
        className={customCssClass}
        fieldName={field}
        formItem={fieldItem}
        name={field}
        key={field}
        readOnly={readOnly}
      />
    );
  }

  if (fieldItem.type === 'select_suggest' && field === 'Product Category') {
    return (
      <Form.Item
        name={field}
        label={fieldItem.label}
        key={field}
        className={customCssClass}
        required={fieldItem.is_required}
      >
        <EquityTouchProductCategory name={field} initialValue={initialFormData[field]} />
      </Form.Item>
    );
  }

  if (fieldItem.type === 'textarea') {
    let defaultValue: null | string;

    if (fieldItem.from_database === null) {
      defaultValue = '';
    } else if (typeof fieldItem.from_database === 'object') {
      if (Array.isArray(fieldItem.from_database)) {
        defaultValue = fieldItem.from_database.join(', ');
      } else {
        defaultValue = JSON.stringify(fieldItem.from_database);
      }
    } else {
      defaultValue = fieldItem.from_database.toString();
    }

    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label={fieldItem.label}
        key={field}
        required={fieldItem.is_required}
      >
        <Input.TextArea
          name={field}
          rows={fieldItem.rows || 1}
          disabled={readOnly}
          defaultValue={defaultValue}
        />
      </Form.Item>
    );
  }

  if (fieldItem.type === 'checkbox') {
    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label=" "
        valuePropName="from_database"
        key={field}
        required={fieldItem.is_required}
      >
        {isBulk ? (
          <BulkEquityTouchAddonCheckbox name={field} label={fieldItem.label} disabled={readOnly} />
        ) : (
          <Checkbox name={field} disabled={readOnly}>
            {fieldItem.label}
          </Checkbox>
        )}
      </Form.Item>
    );
  }

  if (fieldItem.type === 'number') {
    let defaultValue =
      typeof fieldItem?.from_database === 'number' ? fieldItem.from_database : undefined;

    if (fieldItem.label === 'Equity Check' || field === 'Equity Check') {
      defaultValue =
        typeof fieldItem?.from_database === 'number'
          ? (fieldItem.from_database as number) / 1000000
          : undefined;
    }

    return (
      <Form.Item
        className={customCssClass}
        name={field}
        label={fieldItem.label}
        key={field}
        required={fieldItem.is_required}
      >
        <InputNumber name={field} type="number" disabled={readOnly} defaultValue={defaultValue} />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      className={customCssClass}
      name={field}
      label={fieldItem.label}
      key={field}
      required={fieldItem.is_required}
    >
      <Input name={field} disabled={readOnly || fieldItem.disabled} />
    </Form.Item>
  );
};
