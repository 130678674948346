import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
// styles
import './SearchHeader.styles.scss';
// utils
import { mapTagsToNamesAndOperators } from '@utils/filters/mapTagsToNamesAndOperators';
// redux
import { actions as searchActions } from '@features/grid/search';
import { selectors as fullScreenSelectors } from '@redux/ui/settings/fullscreen';
import { selectors as filterSelectors } from '@optx/features/grid/filter';
import { selectors as searchesSelectors } from '@redux/company/saved-searches';
import { selectors as searchSelectors } from '@features/grid/search';
import { selectors as viewSelectors } from '@features/grid/view';
import { actions as sortActions } from '@redux/company/search/sort';
// components
import SearchCompaniesHints from '@optx/components/pages/Home/SearchCompaniesHints';
import ClearSearchButton from '@shared/view/molecules/Buttons/ClearSearchButton';
import CompanySearch from './CompanySearch';
import FilterTags from './FilterTags';
// eslint-disable-next-line max-len
import { default as CombinedSearchFilterTags } from '@optx/components/common/table/TableSavedSearches/cells/company/FilterTags';
import GridFullScreen from '@optx/shared/view/molecules/FullScreen/GridFullScreen';

const SearchHeader = () => {
  const dispatch = useDispatch();
  const showRestoreIcon = useSelector(filterSelectors.showCustomClearFilter('advancedSearch'));
  const isFullScreen = useSelector(fullScreenSelectors.isFullscreen);
  const customClearedFilter = useSelector(filterSelectors.getCustomClearedFilter('advancedSearch'));

  const savedSearches = useSelector(searchesSelectors.getSavedSearches);
  const view = useSelector(viewSelectors.getView('advancedSearch'));
  const selectedCombinedSearch = savedSearches.find(search => search.unique_id === view.id);
  const listType = useSelector(searchSelectors.getListType('advancedSearch'));

  const filterNames = mapTagsToNamesAndOperators(
    selectedCombinedSearch?.parent_search_ids,
    savedSearches,
    selectedCombinedSearch?.logical_operators
  );

  return (
    <div className="search-header-content">
      <CompanySearch className="antd-search-input" autoComplete="off" />
      {listType === 'combined' ? (
        <CombinedSearchFilterTags
          filterNames={filterNames}
          listType={listType}
          savedSearches={filterNames}
        />
      ) : (
        <FilterTags />
      )}
      <Space size={4} style={{ marginLeft: 'auto' }}>
        <SearchCompaniesHints />
        <ClearSearchButton
          title="Clear All"
          placement="bottom"
          isVisible={showRestoreIcon || listType === 'combined'}
          onClick={() => {
            dispatch(sortActions.similarCompaniesSortingAction(false));
            dispatch(
              searchActions.clearSearch({ gridKey: 'advancedSearch', data: customClearedFilter })
            );
          }}
        />
        {isFullScreen && <GridFullScreen />}
      </Space>
    </div>
  );
};

export default React.memo(SearchHeader);
