import React, { useEffect, useState } from 'react';
import { Col, Row, Tooltip, Typography } from 'antd';
import { LinkedinFilled } from '@ant-design/icons';
// models
import { CompanyProfile } from '@optx/models/Company';
import { CompanyUserContact } from '@optx/models/api/contacts';
// constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
import { SHORT_MONTH_YEAR_DATE_FORMAT } from '@optx/constants/format/date';
// utils
import { formatDate, formatMoney } from '@optx/utils/format';
import { getDateColor } from '@utils/text';
import { convertToURL } from '@optx/utils/url';
// components
import { MultipleDate } from '@components/common/MultipleDate';
import CompanySourceDescriptions from '@optx/components/pages/CompanyProfile/ProfileBanner/CompanySourceDescriptions';
import CopyIcon from '@optx/components/common/CopyIcon';
import { CompanyTagsOverflow, ListTagsOverflow } from '@optx/features/tags/overflow-tags';
import { KPI } from '../../kpi';

const { Text } = Typography;

interface OPTXInsightsProps {
  companyProfile: CompanyProfile;
}

const OPTXInsights: React.FC<OPTXInsightsProps> = ({ companyProfile }) => {
  const { contacts } = companyProfile;
  const companyProfileWatchLists =
    companyProfile.et_watchlists && companyProfile.regular_watchlists
      ? [...companyProfile.et_watchlists, ...companyProfile.regular_watchlists]
      : companyProfile.et_watchlists
      ? companyProfile.et_watchlists
      : companyProfile.regular_watchlists;
  const [primaryContact, setPrimaryContact] = useState<CompanyUserContact | undefined>();

  const lastRoundLabel =
    companyProfile.last_raised_amount === null && companyProfile.last_round === null
      ? 'Unknown'
      : companyProfile.last_round;

  useEffect(() => {
    setPrimaryContact(contacts.find(item => item.primary_contact));
  }, [contacts]);

  return (
    <>
      <KPI companyProfile={companyProfile} />
      <Row justify="space-between" className="insights-row">
        <Col span={24} style={{ marginBottom: 4 }}>
          <Text strong>Key Info</Text>
        </Col>
        <Col span={6} className="key-info-ceo">
          <Text type="secondary" strong>
            CEO
          </Text>
        </Col>
        <Col span={18} className="key-info-ceo-value">
          <Text strong>{primaryContact?.full_name || DEFAULT_EMPTY_VALUE}</Text>
          <div className="insights-social-media">
            {primaryContact?.linkedin && (
              <Tooltip title={primaryContact?.linkedin}>
                <a
                  href={convertToURL(primaryContact?.linkedin)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="socialmedia"
                >
                  <LinkedinFilled style={{ fontSize: 22, color: '#0077b7' }} />
                </a>
              </Tooltip>
            )}
            {primaryContact?.email && (
              <>
                <CopyIcon text={primaryContact?.email} />
                <a
                  href={`mailto:${primaryContact?.email}`}
                  target="_blank"
                  className="emails"
                  rel="noopener noreferrer"
                >
                  <span>
                    <Tooltip title={primaryContact?.email} placement="topLeft">
                      {primaryContact?.email}
                    </Tooltip>
                  </span>
                </a>
              </>
            )}
          </div>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Year Founded
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>{companyProfile.year_founded || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Stage
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>{companyProfile.stage || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Last Round
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>{lastRoundLabel}</Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Revenue
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>
            {formatMoney(companyProfile.last_rev_update_amount) || DEFAULT_EMPTY_VALUE}
          </Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Pipeline Rank
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>{companyProfile.pipeline_rank || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Crunchbase Rank
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>{companyProfile.cb_rank || DEFAULT_EMPTY_VALUE}</Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Last Touch
          </Text>
        </Col>
        <Col span={18}>
          <Text strong style={{ color: `${getDateColor(companyProfile.last_touch_date)}` }}>
            {companyProfile.last_touch_date
              ? `${formatDate(companyProfile.last_touch_date)}`
              : DEFAULT_EMPTY_VALUE}
          </Text>
        </Col>
        <Col span={6}>
          <Text type="secondary" strong>
            Scheduled Touch
          </Text>
        </Col>
        <Col span={18}>
          <Text strong>
            {companyProfile.next_touch_date ? (
              <MultipleDate
                dates={companyProfile.next_touch_date as string[]}
                format={SHORT_MONTH_YEAR_DATE_FORMAT}
              />
            ) : (
              DEFAULT_EMPTY_VALUE
            )}
          </Text>
        </Col>
        <Col className="source-description">
          <CompanySourceDescriptions company={companyProfile} />
        </Col>
        <Col span={24}>
          <CompanyTagsOverflow
            company={companyProfile}
            companyId={companyProfile.company_id}
            isNewTab
            isAdd
            closable
          />
          <ListTagsOverflow tags={companyProfileWatchLists} className="list-tags__custom-styling" />
        </Col>
      </Row>
    </>
  );
};

export default OPTXInsights;
