import React, { useEffect, useState } from 'react';
import { Button, Col, Tooltip, Typography } from 'antd';
import { Form, Input } from 'formik-antd';
import { useField } from 'formik';
import { isEqual } from 'lodash';
// models
import { BaseField } from '@optx/models/companyFields';
//constants
import { DEFAULT_EMPTY_VALUE } from '@optx/constants/value';
// utils
import { checkSaveButton } from '@optx/utils/proprietaryInfo';
import { handleDropdownKeyEvent } from '@optx/utils/handleDropdownKeyEvent';
// components
import EditPopover from '@optx/components/common/popover/EditPopover';

interface InputFieldProps {
  field?: BaseField;
  value?: string;
  fieldName?: string;
  fieldType?: string;
  isChromePlugin?: boolean;
  onSave?: (fieldType?: string) => void;
  onCancel?: () => void;
}

const InputField: React.FC<InputFieldProps> = ({
  field,
  value,
  onSave,
  isChromePlugin,
  fieldName,
  onCancel,
  fieldType,
}) => {
  const [selected, , setSelected] = useField<string>(field?.id || fieldType || '');
  const [closePopup, setClosePopup] = useState(false);

  const handleInputValues = () => {
    if (selected.value === undefined) {
      return value;
    }

    return selected.value;
  };

  useEffect(() => {
    if (field?.value || field?.value === '') {
      if (!isEqual(field.value, selected.value)) {
        setSelected.setValue(field.value);
      }
    }

    if (value) {
      setSelected.setValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field?.value, value]);

  const handleCancel = () => {
    onCancel && onCancel();
    setClosePopup(true);
  };

  const handleVisibilityChange = (visibility: boolean) => {
    if (value && visibility) {
      setSelected.setValue(value);
    }

    if (!value && visibility) {
      setSelected.setValue('');
    }
  };

  if (field) {
    return (
      <Col span={8} className={field.id}>
        <Form.Item name={field.id}>
          <Typography.Text>{field.label}</Typography.Text>
          <Input
            name={field.id}
            placeholder={field.placeholder}
            disabled={!field.editable}
            value={selected.value ?? ''}
            fast
          />
        </Form.Item>
      </Col>
    );
  }

  const content = (
    <Form.Item style={{ height: '100px' }} name={fieldType ?? ''}>
      <div
        onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
          handleDropdownKeyEvent(event, setClosePopup, onSave, fieldType)
        }
        tabIndex={0}
      >
        <Typography.Text>Edit "{fieldName}" value</Typography.Text>
        <Input value={handleInputValues()} name={fieldType ?? ''} placeholder="value" />
        <div className="profile-information__popover-buttons">
          <Button className="profile-information__cancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="profile-information__save"
            type="primary"
            disabled={checkSaveButton(selected.value, value)}
            onClick={() => {
              onSave && onSave(fieldType);
              setClosePopup(true);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Form.Item>
  );

  return (
    <EditPopover
      closePopup={closePopup}
      placement={isChromePlugin ? 'topRight' : 'top'}
      onVisibilityUpdate={handleVisibilityChange}
      setClosePopup={setClosePopup}
      content={content}
      customOverlayStyle={{ width: '385px' }}
    >
      <Tooltip title={value?.length ? value.toString() : DEFAULT_EMPTY_VALUE} placement="left">
        {value?.length ? value.toString() : DEFAULT_EMPTY_VALUE}
      </Tooltip>
    </EditPopover>
  );
};

export default React.memo(InputField);
