import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Typography } from 'antd';
import { OptionTypeBase } from 'react-select';
import { useField } from 'formik';
import { Select, FormItem } from 'formik-antd';
import { isEqual } from 'lodash';
// models
import { TouchFieldProps } from './interface';
// redux
import { selectors } from '@components/feature/company-individual-edit/state';

const { Option } = Select;

interface SubSectorOptions {
  name: string;
  id: number;
}

const SingleSelectSectorField: React.FC<TouchFieldProps> = ({ field }) => {
  const [selectedField, , setSelectedField] = useField<SubSectorOptions>(field.id);
  const [selectedOption, setSelectedOption] = useState<string>();

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  const selectedState = useSelector(selectors.companyIndividualEdit.subSectorOptions);

  const selectOptions = useMemo(() => {
    return [{ value: 'blank', label: 'None' }, ...selectedState];
  }, [selectedState]);

  useEffect(() => {
    if (field.value && typeof field.value === 'string') {
      setSelectedOption(field.value);
    } else if (field.value) {
      const selectedFromList = selectOptions.find(item => item.label === field.value.name)?.value;

      if (selectedFromList) {
        setSelectedOption(selectedFromList);
      } else {
        setSelectedOption(field.value.name);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, selectOptions]);

  useEffect(() => {
    if (selectedOption) {
      const selectedFromList = selectOptions.find(item => item.value === selectedOption)?.label;

      if (
        !isEqual(selectedField.value, {
          name: selectedFromList ?? '',
          id: parseInt(selectedOption) ? parseInt(selectedOption) : 0,
        })
      ) {
        setSelectedField.setValue({
          name: selectedFromList ?? '',
          id: parseInt(selectedOption) ? parseInt(selectedOption) : 0,
        });
      }
    } else {
      setSelectedField.setValue({ name: '', id: 0 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  return (
    <Col span={8} className={field.id}>
      <Typography.Text>{field.label}</Typography.Text>
      <FormItem name={field.id}>
        <Select
          showSearch
          name="subSector"
          value={selectedOption}
          onChange={handleChange}
          getPopupContainer={trigger => trigger.parentElement!}
          filterOption={(input: string, option: OptionTypeBase | undefined) => {
            const fullName = option ? `${option?.children.props.children}` : '';

            return fullName.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        >
          {selectOptions?.map((option: OptionTypeBase) => (
            <Option key={option.value as string} value={option.value as string}>
              <span>{option.label}</span>
            </Option>
          ))}
        </Select>
      </FormItem>
    </Col>
  );
};

export default React.memo(SingleSelectSectorField);
